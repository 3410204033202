import React from 'react';
import RegistrationForm from './RegistrationForm';
import { useHistory} from 'react-router-dom';

function LoginPage(props) {
    let history = useHistory();
    return (
        <div>
            <RegistrationForm {...props} history={history}/>
        </div>
    )
}

export default LoginPage