import React from 'react';
import emailjs from '@emailjs/browser';
import random from 'randomstring';
import axios from 'axios';

class ForgotPasswordContent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            error: "",
            enabled: false,
            succesMessage: "",
            resetError: "",
            key: "",
            newPassword: "",
            confirmPassword: ""
        }
        this.handleEmailInputChange = this.handleEmailInputChange.bind(this)
        this.handleKeyInputChange = this.handleKeyInputChange.bind(this)
        this.handleNewPasswordInputChange = this.handleNewPasswordInputChange.bind(this)
        this.handleConfirmPasswordInputChange = this.handleConfirmPasswordInputChange.bind(this)
        this.sendMail = this.sendMail.bind(this)
    }

    handleEmailInputChange(event) {
        this.setState({
            email: event.target.value
        });
    }

    handleKeyInputChange(event) {
        this.setState(
            {
                key: event.target.value
            });
    }

    handleNewPasswordInputChange(event) {
        this.setState(
            {
                newPassword: event.target.value
            });
    }

    handleConfirmPasswordInputChange(event) {
        this.setState(
            {
                confirmPassword: event.target.value
            });
    }

    async sendMail(){
        this.setState({
            error: ""
        });
        if (this.state.email !== "") {
            const key = random.generate();
            const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/users/email-exists/${this.state.email}/`, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            });
            if (!data) {
                this.setState({ 
                    error: "Er is geen account gevonden met het ingevoerde e-mail adres."
                })
            } else {
                emailjs.send("service_7mh1x18", "template_27sn0fe", { first_name: data, url: key, to: this.state.email }, "TB7RngjA3mwoS21ix");
                await axios.post(`${process.env.REACT_APP_API_URL}/users/save-password-reset/`, { email: this.state.email, key: key },{
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }
                });
                this.setState({
                    enabled: true,
                    succesMessage: "Er is een e-mail verstuurd, indien u geen e-mail ontvangen heeft kan u contact opnemen met Koen Pierlet."

                })
            }
        } else {
            this.setState({
                error: "Gelieve eerst een e-mail adres in te vullen."
            });
        }
    }

    async resetPassword() {
        const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/users/reset-password/${this.state.key}/`,  { newPassword: this.state.newPassword, confirmPassword: this.state.confirmPassword }, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
        data ? window.location.reload(false) : this.setState({ resetError:  "Er is iets fout gelopen, controleer of je de code juist hebt ingegeven en zorg ervoor dat je paswoorden overeenkomen." });
    }

    render() {
        return (
            <>
                <div className="forgot-password-header">
                    <h1>Verander wachtwoord</h1>
                </div>

                <div className="forgot-password-body">
                    <h2>Vul je email adres in en je ontvangt een e-mail met een link waar je je paswoord kan veranderen.</h2>
                    <p style={{color: "red", paddingBottom: "10px"}}>{this.state.error}</p>
                    <p style={{color: "green", paddingBottom: "10px"}}>{this.state.succesMessage}</p>
                    <div className='forgot-password-body-input'>
                        <input id="email" name="email" type="text" onChange={this.handleEmailInputChange}></input>
                        <label>Email</label>
                    </div>
                    <button disabled={this.state.enabled} onClick={() => this.sendMail()}><span>Verstuur e-mail</span></button>
                    {
                        this.state.enabled &&
                            <div>
                                    <div className="forgot-password-content-input">
                                        {this.state.resetError !== "" && <p>{this.state.resetError}</p>}
                                        <div>
                                            <label for="key">Code</label>
                                            <input id="key" name="key" type="text" onChange={this.handleKeyInputChange} value={this.state.key} />
                                        </div>
                                        <div>
                                            <label for="newPassword">Niew paswoord</label>
                                            <input id="newPassword" name="newPassword" type="password" onChange={this.handleNewPasswordInputChange} value={this.state.newPassword} />
                                        </div>
                                        <div>
                                            <label for="confirmPassword">Bevestig nieuw paswoord</label>
                                            <input id="confirmPassword" name="confirmPassword" type="password" onChange={this.handleConfirmPasswordInputChange} value={this.state.confirmPassword} />
                                        </div>
                                    </div>
                                    <button onClick={() => this.resetPassword()}>Verander paswoord</button>
                            </div>
                    }
                </div>
            </>
        )
    }
}

export default ForgotPasswordContent;