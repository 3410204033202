import React from 'react';
import ForgotPasswordContent from './ForgotPasswordContent';
import { useHistory } from 'react-router-dom';

function ForgotPasswordPage(props) {
    let history = useHistory();

    return (
        <div>
            <ForgotPasswordContent history={history} />
        </div>
    )
}

export default ForgotPasswordPage