import React from 'react';
import { Link } from 'react-router-dom';

class AdminContent extends React.Component {
    render() {
        return (
            <div className="admin">
                <div className="admin-header">
                    <h1>Administator pagina</h1>
                </div>

                <div className="admin-content">
                    <div>
                        <h4>Vragen beheren/toevoegen </h4>
                        <button><Link to="question-overview"><span>Vragen</span></Link></button>
                        <h4>Onderdelen beheren/toevoegen</h4>
                        <button><Link to="subject-overzicht"><span>Onderdelen</span></Link></button>
                        <h4>Examens beheren/toevoegen/toewijzen</h4>
                        <button><Link to="exam-overview"><span>Examens</span></Link></button>
                        <h4>Gebruikers beheren</h4>
                        <button><Link to="user-overview"><span>Gebruikers</span></Link></button>
                    </div>
                </div>
            </div>
        )
    }
}

export default AdminContent;