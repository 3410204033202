import React from 'react';
import Header from './Header';
import ExamOverviewContent from './ExamOverviewContent';
import Footer from './Footer';

function ExamOverviewPage(props) {
    return (
        <div>
            <Header  {...props} />
            <ExamOverviewContent {...props}/>
            <Footer />
        </div>
    )
}

export default ExamOverviewPage