import React from 'react';
import Pdf from './documents/PDFAanmeldprocedure.pdf'

class ContactContent extends React.Component {
    render() {
        return (
            <div className="contact-page">
                <div className="contact-page-header">
                    <h1>Info</h1>
                </div>

                <div className="contact-page-message">
                    <div className="contact-page-message-content">
                        <div className='content-part'>
                            <h4>Secretariaat LVZC:</h4>
                            <p>Adres: Drieskensstraat 46, 2300 Turnhout</p>
                            <p>Telefoon: +32 (14) 89 44 60</p>
                            <p>Website: <a id="link-to-main-website" href="http://www.lvzc.be/" >http://www.lvzc.be</a></p>
                        </div>
                        <div className='content-part'>
                            <h4>Vragen over zweefvliegexamen.be?</h4>
                            <h5>Contacteer Koen Pierlet:</h5>
                            <p>E-mail: koen.pierlet@dewouw.net</p>
                            <p>GSM: 0496 67 25 05</p>
                        </div>
                        <div className='content-part'>
                            <h4>Geslaagd voor je proefexamen?</h4>
                            <h5>Dan kun je zelf je theorie-examen SPL bij DGLV aanvragen in het "Aviation Portal"</h5>
                            <div className='buttons'>
                                <a href='https://es.mobilit.fgov.be/eLoket/main/home?lang=nl'  target="_blank" rel='noreferrer' className='button buttonBlue'>Aviation Portal DGLV</a>
                                <a href={Pdf}  target="_blank" rel='noreferrer' className='button buttonYellow'>PDF aanmeldprocedure</a>
                            </div>
                        </div>
                    </div>

                    <div className="contact-page-credentials">
                        <div className="contact-page-credentials-map">
                            <iframe title="iframe" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d9971.991920476235!2d4.943269518537258!3d51.32955698138062!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c6b17bf7896b1d%3A0x34d4d30345aa843!2sDrieskensstraat%2046%2C%202300%20Turnhout!5e0!3m2!1snl!2sbe!4v1587914251998!5m2!1snl!2sbe"></iframe>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ContactContent;