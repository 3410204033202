import React from 'react';
import Header from './Header';
import SelectUsersForExamContent from './SelectUsersForExamContent';
import Footer from './Footer';
import { useHistory, useParams } from 'react-router-dom';

function SelectUsersForExamPage(props) {
    let {examID} = useParams();
    let history = useHistory();
    return (
        <div>
            <Header {...props} />
            <SelectUsersForExamContent history={history} examID={examID}/>
            <Footer />
        </div>
    )
}

export default SelectUsersForExamPage;