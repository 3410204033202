import React from 'react';
import Header from './Header';
import SubjectOverzichtContent from './SubjectOverzichtContent';
import Footer from './Footer';

function SubjectOverzichtPage(props) {
    return (
        <div>
            <Header {...props} />
            <SubjectOverzichtContent {...props} />
            <Footer />
        </div>
    )
}

export default SubjectOverzichtPage