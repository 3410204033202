import React, { useState, useEffect } from 'react';
import cookie from 'react-cookies';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import axios from 'axios';
import Hypnosis from "react-cssfx-loading/lib/Hypnosis";

function AddQuestionContent(props) {
    const [subjects, setSubjects] = useState([]);
    const [exams, setExams] = useState([]);
    const [image, setImage] = useState({});
    const [subjectsLoaded, setSubjectsLoaded] = useState(false);
    const [examsLoaded, setExamsLoaded] = useState(false);
    let defaultSubjectID;
    if (subjects.length > 0) {
        defaultSubjectID = subjects[0].subjectID;
    }

    useEffect(() => {
        async function fetchData() {
            let unMounted = false;
            let jwt = cookie.load('jwt');
            const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/questions/get-data-for-add-question`, {
                cache: 'no-store',
                headers: {
                    'Authorization': `Bearer ${jwt}`,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            });
            setSubjects(data.subjects)
            setExams(data.exams)
            if (!unMounted) {
                setSubjectsLoaded(true)
                setExamsLoaded(true)
            }
            return () => { unMounted = true }
        }
        fetchData();
    }, []);

    const validate = values => {
        const errors = {};
        if (!values.question) {
            errors.question = 'Gelieve een opgave in te vullen voor de vraag.';
        }
        else if (values.question.length > 4000) {
            errors.question = 'De opgave van de vraag mag niet langer zijn dan 4000 tekens.';
        }
        if (!values.possibleAnswer1) {
            errors.possibleAnswer1 = 'Gelieve mogelijk antwoord 1 in te vullen voor de vraag.';
        }
        else if (values.possibleAnswer1.length > 4000) {
            errors.possibleAnswer1 = 'mogelijk antwoord 1 van de vraag mag niet langer zijn dan 4000 tekens.';
        }
        if (!values.possibleAnswer2) {
            errors.possibleAnswer2 = 'Gelieve mogelijk antwoord 2 in te vullen voor de vraag.';
        }
        else if (values.possibleAnswer2.length > 4000) {
            errors.possibleAnswer2 = 'mogelijk antwoord 2 van de vraag mag niet langer zijn dan 4000 tekens.';
        }
        if (!values.possibleAnswer3) {
            errors.possibleAnswer3 = 'Gelieve mogelijk antwoord 3 in te vullen voor de vraag.';
        }
        else if (values.possibleAnswer3.length > 4000) {
            errors.possibleAnswer3 = 'mogelijk antwoord 3 van de vraag mag niet langer zijn dan 4000 tekens.';
        }
        if (!values.possibleAnswer4) {
            errors.possibleAnswer4 = 'Gelieve mogelijk antwoord 4 in te vullen voor de vraag.';
        }
        else if (values.possibleAnswer4.length > 4000) {
            errors.possibleAnswer4 = 'mogelijk antwoord 4 van de vraag mag niet langer zijn dan 4000 tekens.';
        }
        if (values.explanation.length > 4000) {
            errors.explanation = 'De uitleg van de vraag mag niet langer zijn dan 4000 tekens.';
        }
        return errors;
    };

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            question: '',
            possibleAnswer1: '',
            possibleAnswer2: '',
            possibleAnswer3: '',
            possibleAnswer4: '',
            correctAnswer: '',
            subjectID: defaultSubjectID,
            difficulty: 'EASY',
            explanation: '',
            examsIDs: []
        },
        validate,
        onSubmit: values => {
            addQuestionFunction(values)
        }
    })

    function handleImageInputChange(event) {
        event.preventDefault();
        setImage(event.target.files[0])
    };

    function addQuestionFunction(values) {
        let correct = '';
        if (formik.values.correctAnswer === '') {
            correct = formik.values.possibleAnswer1;
        }
        else {
            correct = formik.values.correctAnswer;
        }
        let jwt = cookie.load('jwt');
        const formData = new FormData();
        formData.append('image', new Blob([image], {
            type: "multipart/form-data"
        }), image.name);
        formData.append('question', new Blob([JSON.stringify({
            question: values.question, possibleAnswer1: values.possibleAnswer1, possibleAnswer2: values.possibleAnswer2, possibleAnswer3: values.possibleAnswer3, possibleAnswer4: values.possibleAnswer4, correctAnswer: correct, subjectID: values.subjectID, difficulty: values.difficulty, examsIDs: values.examsIDs, explanation: values.explanation
        })], {
            type: "application/json"
        }))
        fetch(`${process.env.REACT_APP_API_URL}/questions/add-question`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${jwt}`
            },
            body: formData
        }).then(
            res => res.json()
        ).then(
            () => {
                props.history.push('/question-overview')
            }
        )
    }

    return (
        <div className="add-question">
                <div>
                    <div className="add-question-header-box">
                        <Link to="/question-overview"><ion-icon name="arrow-back-outline"></ion-icon> Back</Link>
                        <div className="add-question-header">
                            <h1>Vul hier de gegevens in van de vraag die je wil toevoegen.</h1>
                        </div>
                    </div>
                    <div className="add-question-body-box">
                        {subjectsLoaded && examsLoaded ?
                        <div className="add-question-body">

                            <form onSubmit={formik.handleSubmit}>
                                <div className="add-question-body-errormsg">
                                    {formik.errors.question &&
                                        <div>{formik.errors.question}</div>}
                                    {formik.errors.correctAnswer &&
                                        <div>{formik.errors.correctAnswer}</div>}
                                    {formik.errors.possibleAnswer1 &&
                                        <div>{formik.errors.possibleAnswer1}</div>}
                                    {formik.errors.possibleAnswer2 &&
                                        <div>{formik.errors.possibleAnswer2}</div>}
                                    {formik.errors.possibleAnswer3 &&
                                        <div>{formik.errors.possibleAnswer3}</div>}
                                    {formik.errors.possibleAnswer4 &&
                                        <div>{formik.errors.possibleAnswer4}</div>}
                                    {formik.errors.explanation &&
                                        <div>{formik.errors.explanation}</div>}
                                </div>
                                <div className="add-question-body-element">
                                    <div className="add-question-body-element-content">
                                        <label>Opgave:</label><input id="question" name="question" type="text" onChange={formik.handleChange} value={formik.values.question}></input>
                                    </div>
                                    <p className="warning-text">Let op de volgorde waarin je de mogelijke antwoorden invult blijft behouden op het examen!</p>
                                    <div className="add-question-body-element-content">
                                        <label>Mogelijk antwoord 1:</label><input id="possibleAnswer1" name="possibleAnswer1" type="text" onChange={formik.handleChange} value={formik.values.possibleAnswer1}></input>
                                    </div>
                                    <div className="add-question-body-element-content">
                                        <label>Mogelijk antwoord 2:</label><input id="possibleAnswer2" name="possibleAnswer2" type="text" onChange={formik.handleChange} value={formik.values.possibleAnswer2}></input>
                                    </div>
                                    <div className="add-question-body-element-content">
                                        <label>Mogelijk antwoord 3:</label><input id="possibleAnswer3" name="possibleAnswer3" type="text" onChange={formik.handleChange} value={formik.values.possibleAnswer3}></input>
                                    </div>
                                    <div className="add-question-body-element-content">
                                        <label>Mogelijk antwoord 4:</label><input id="possibleAnswer4" name="possibleAnswer4" type="text" onChange={formik.handleChange} value={formik.values.possibleAnswer4}></input>
                                    </div>
                                    <div className="add-question-body-element-content-dropdown">
                                        <p>Selecteer het juiste antwoord:</p>
                                        <select id="correctAnswer" name="correctAnswer" onChange={formik.handleChange}>
                                            <option value={formik.values.possibleAnswer1}>Mogelijk antwoord 1</option>
                                            <option value={formik.values.possibleAnswer2}>Mogelijk antwoord 2</option>
                                            <option value={formik.values.possibleAnswer3}>Mogelijk antwoord 3</option>
                                            <option value={formik.values.possibleAnswer4}>Mogelijk antwoord 4</option>
                                        </select>
                                    </div>
                                    <div className="add-question-body-element2-explanation">
                                        <label>Uitleg: </label><textarea id="explanation" name="explanation" type="text" onChange={formik.handleChange} value={formik.values.explanation}></textarea>
                                    </div>

                                    <div className="add-question-body-element-content">
                                        <label>Selecteer een afbeelding:</label><input id="image" name="image" type="file" onChange={(event) => handleImageInputChange(event)}></input>
                                    </div>

                                    <div className="add-question-body-element-footer">
                                        <div className="add-question-body-element-footer-content">
                                            <p>Onderdeel:</p>
                                            <select id="subjectID" name="subjectID" onChange={formik.handleChange}>
                                                {subjects.map((element) => (
                                                    <option key={element.subjectID} value={element.subjectID}>{element.name}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </div>


                                <div className="add-question-body-element2">
                                    <div className="add-question-body-element2-tags">
                                        <p className="examen">Examen:</p>
                                        <p className="moeilijkheidsgraad">Moeilijkheidsgraad:</p>
                                    </div>
                                    <div className="add-question-body-element2-moeilijkheidsgraad">
                                        <p></p>
                                        <p><input type="radio" name="difficulty" checked={formik.values.difficulty === "EASY"} value="EASY" onChange={formik.handleChange} />makkelijk</p>
                                        <p><input type="radio" name="difficulty" checked={formik.values.difficulty === "MEDIUM"} value="MEDIUM" onChange={formik.handleChange} />gemiddeld</p>
                                        <p><input type="radio" name="difficulty" checked={formik.values.difficulty === "HARD"} value="HARD" onChange={formik.handleChange} />moeilijk</p>
                                    </div>
                                    {exams.map((element) => (
                                        <div key={element.examID} className="add-question-body-element2-checkbox">
                                            {element.examName !== null &&
                                                <div>
                                                    <input type="checkbox" id="examsIDs" name="examsIDs" value={element.examID} checked={formik.values.examsIDs.includes(element.examID)} onChange={formik.handleChange} />
                                                    <label className={element.examName}>{element.examName}</label>
                                                </div>
                                            }
                                        </div>
                                    ))}
                                </div>
                                <div className="add-question-body-footer">
                                    <button type="submit"><span>Voeg toe</span></button>
                                </div>
                            </form>
                        </div>
                        :
                        <div className="loader-animation">
                            <Hypnosis  color="#3399ff" width="50px" height="50px" duration="2s" />
                        </div>
                    }
                    </div>
                </div>
                
        </div>
    )
}

export default AddQuestionContent;