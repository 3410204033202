import axios from 'axios';
import React from 'react';
import cookie from 'react-cookies';
import { Link } from 'react-router-dom';
import Hypnosis from "react-cssfx-loading/lib/Hypnosis";

class ExamContent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            exam: {},
            index: 0,
            selectedAnswer: "",
            selectedAnswers: [],
            errorMessage: "",
            dataLoaded: false,
            submitButtonIsDisabled: false
        }
        this.previousQuestion = this.previousQuestion.bind(this);
        this.nextQuestion = this.nextQuestion.bind(this);
        this.goToQuestion = this.goToQuestion.bind(this);
        this.handleSelectedAnswerInputChange = this.handleSelectedAnswerInputChange.bind(this);
        this.submitConfirmation = this.submitConfirmation.bind(this);
        this.cancelSubmit = this.cancelSubmit.bind(this);
        this.submitExam = this.submitExam.bind(this);
    }

    async componentDidMount() {
        let jwt = cookie.load('jwt');
        const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/exams/get-exam-viewmodel-by-id/${this.props.examID}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${jwt}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
        for (let i = 0; i < data.questions.length; i++) {
            data.questions[i].guessedAnswer = "";
        }
        this.setState({
            exam: data,
            dataLoaded: true
        })
    }

    previousQuestion() {
        if (this.state.index !== 0) {
            let i = this.state.index;
            let examCopy = this.state.exam;
            examCopy.questions[i].guessedAnswer = this.state.selectedAnswer;
            if (this.state.selectedAnswer !== "") {
                let button = document.getElementById("test" + i);
                button.className = "filledIn";
            }
            i = this.state.index - 1;
            this.setState({
                index: i,
                exam: examCopy,
                selectedAnswer: this.state.exam.questions[i].guessedAnswer
            })
        }
        else {
            this.setState({
                errorMessage: ""
            })
        }
    }

    nextQuestion() {
        if (this.state.index < this.state.exam.questions.length - 1) {
            let i = this.state.index;
            let examCopy = this.state.exam;
            examCopy.questions[i].guessedAnswer = this.state.selectedAnswer;
            if (this.state.selectedAnswer !== "") {
                let button = document.getElementById("test" + i);
                button.className = "filledIn";
            }
            i = this.state.index + 1;
            this.setState({
                index: i,
                exam: examCopy,
                selectedAnswer: this.state.exam.questions[i].guessedAnswer
            })
        }
        else {
            this.setState({
                errorMessage: ""
            })
        }
    }

    goToQuestion(index) {
        let i = this.state.index;
        let examCopy = this.state.exam;
        examCopy.questions[i].guessedAnswer = this.state.selectedAnswer;
        if (this.state.selectedAnswer !== "") {
            let button = document.getElementById("test" + i);
            button.className = "filledIn";
        }
        this.setState({
            index: index,
            exam: examCopy,
            selectedAnswer: this.state.exam.questions[index].guessedAnswer,
            errorMessage: ""
        })
    }

    handleSelectedAnswerInputChange(event) {
        this.setState({
            selectedAnswer: event.target.value,
        });
    }

    submitConfirmation() {
        let i = this.state.index;
        let examCopy = this.state.exam;
        examCopy.questions[i].guessedAnswer = this.state.selectedAnswer;
        if (this.state.selectedAnswer !== "") {
            let button = document.getElementById("test" + i);
            button.className = "filledIn";
        }
        this.setState({
            exam: examCopy
        });
        let tempList = examCopy.questions.filter(question => question.guessedAnswer === "");
        if (tempList.length === 0) {
            this.setState({
                errorMessage: "Je hebt alle vragen ingevuld, ben je zeker dat je wil indienen?"
            })
        }
        else {
            this.setState({
                errorMessage: "Je hebt " + tempList.length + " vra(a)g(en) nog niet ingevuld, ben je zeker dat je wil indienen?"
            })
        }
    }

    cancelSubmit() {
        this.setState({
            errorMessage: ""
        })
    }

    async submitExam() {
        this.setState({
            submitButtonIsDisabled: true,
        });
        let jwt = cookie.load('jwt');
        let guessedQuestions = this.state.exam.questions.map((question, index) => ({
            questionID: question.questionID,
            subjectID: question.subjectID,
            question: question.question,
            guessedAnswer: question.guessedAnswer,
            questionNumber: index
        }))
        const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/exam-chances/check-exam/${this.props.examID}`, JSON.stringify(guessedQuestions), {
            headers: {
                'Authorization': `Bearer ${jwt}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });
        this.props.history.push(`/made-mistakes/${data}`)
    }

    render() {
        return (
            <div className="exam-content">
                <Link className="go-back" to="/subjects"><ion-icon name="arrow-back-outline"></ion-icon> Back</Link>
                <div className="exam-content-body">
                    {this.state.dataLoaded ?
                        <div className="exam-content-body-elements">
                            {this.state.exam.questions[this.state.index] !== undefined &&
                                <div>

                                    <div className="exam-content-body-elements-question">
                                        <h1>{this.state.exam.questions[this.state.index].subject}</h1>
                                        <p>{this.state.index + 1 + ". " + this.state.exam.questions[this.state.index].question}</p>
                                    </div>

                                    <div className="exam-content-body-elements-image">
                                        {this.state.exam.questions[this.state.index].image !== null &&
                                            <img alt="" src={this.state.exam.questions[this.state.index].image.fileDownloadUrl} />
                                        }
                                    </div>

                                    <div className="exam-content-body-elements-checkbox">
                                        {this.state.exam.questions[this.state.index].possibleAnswers.map((answer, index) => (
                                            <div key={index}>
                                                {index === 0 ? <p><input type="radio" name="answer" value={answer} checked={this.state.selectedAnswer === answer} onChange={(event) => this.handleSelectedAnswerInputChange(event)} />a.&nbsp;<span>{answer}</span></p>
                                                    : index === 1 ? <p><input type="radio" name="answer" value={answer} checked={this.state.selectedAnswer === answer} onChange={(event) => this.handleSelectedAnswerInputChange(event)} />b.&nbsp;<span> {answer}</span></p>
                                                        : index === 2 ? <p><input type="radio" name="answer" value={answer} checked={this.state.selectedAnswer === answer} onChange={(event) => this.handleSelectedAnswerInputChange(event)} />c.&nbsp;<span> {answer}</span></p>
                                                            : index === 3 ? <p><input type="radio" name="answer" value={answer} checked={this.state.selectedAnswer === answer} onChange={(event) => this.handleSelectedAnswerInputChange(event)} />d.&nbsp;<span> {answer}</span></p>
                                                                : <p></p>
                                                }
                                            </div>
                                        ))}
                                    </div>

                                    <div className="exam-content-body-elements-gotrough">
                                        <div className="exam-content-body-elements-gotrough-arrows">
                                            {this.state.index !== -1 &&
                                                <button className={this.state.index === 0 ? "disabled" : ""} onClick={() => this.previousQuestion()}><span><ion-icon name="arrow-undo"></ion-icon></span></button>
                                            }
                                            {this.state.index < this.state.exam.questions.length &&
                                                <button className={this.state.index === this.state.exam.questions.length - 1 ? "disabled" : ""} onClick={() => this.nextQuestion()}><span><ion-icon name="arrow-redo"></ion-icon></span></button>
                                            }
                                        </div>
                                        <div className="exam-content-body-elements-gotrough-indienen">
                                            <button onClick={() => this.submitConfirmation()}><span>indienen</span></button>
                                        </div>
                                    </div>

                                    {this.state.errorMessage !== "" &&
                                        <div className="exam-content-body-elements-confirm">
                                            <p>{this.state.errorMessage}</p>
                                            <button disabled={this.state.submitButtonIsDisabled} className={this.state.submitButtonIsDisabled ? "disabled" : ""} onClick={() => this.submitExam()}><span>ja, toch indienen</span></button>
                                            <button onClick={() => this.cancelSubmit()}><span>annuleren</span></button>
                                        </div>
                                    }
                                    <div className="exam-content-body-elements-navigationbar">
                                        {this.state.exam.questions.map((question, index) => (
                                            <div key={index} className="exam-content-body-elements-navigationbar-overview">
                                                <button id={"test" + index} className="" onClick={() => this.goToQuestion(index)}>{index + 1}</button>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            }
                        </div>
                        :
                        <div className="exam-content-footer">
                            <Hypnosis  color="#3399ff" width="50px" height="50px" duration="2s" />
                        </div>
                    }
                </div>
            </div>
        )
    }
}

export default ExamContent;