import React from 'react';
import Header from './Header';
import DeleteExamContent from './DeleteExamContent';
import Footer from './Footer';
import { useHistory, useParams } from 'react-router-dom';

function DeleteExamPage(props) {
    let {examID} = useParams();
    let history = useHistory();
    return (
        <div>
            <Header {...props} />
            <DeleteExamContent history={history} examID={examID}/>
            <Footer />
        </div>
    )
}

export default DeleteExamPage;