import React from 'react';
import LoginForm from './LoginForm';
import { useHistory } from 'react-router-dom';

function LoginPage(props) {
    let history = useHistory();

    return (
        <div>
            <LoginForm history={history} />
        </div>
    )
}

export default LoginPage