import React, { useState, useEffect } from 'react';
import cookie from 'react-cookies';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';

function EditExamContent(props) {
    const [exam, setExam] = useState({});
    const [dataLoaded, setDataLoaded] = useState(false);

    useEffect(() => {
        let unMounted = false;
        let jwt = cookie.load('jwt');
        fetch(`${process.env.REACT_APP_API_URL}/exams/${props.examID}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${jwt}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(
            res => res.json()
        ).then(
            (result) => {
                setExam(result)
                if (!unMounted) {
                    setDataLoaded(true)
                }
            }
        )
        return () => { unMounted = true }
    }, []);

    const validate = values => {
        const errors = {};
        if (!values.examName) {
            errors.examName = 'Gelieve een naam in te vullen voor het examen.';
        } else if (values.examName.length > 40) {
            errors.examName = 'De naam van het examen mag niet langer zijn dan 40 tekens.';
        }
        return errors;
    };

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            examName: exam.examName
        },
        validate,
        onSubmit: values => {
            editExamFunction(values)
        }
    })

    function editExamFunction(values) {
        let jwt = cookie.load('jwt');
        fetch(`${process.env.REACT_APP_API_URL}/exams/edit-exam`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${jwt}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                examID: props.examID, examName: values.examName
            })
        }).then(
            res => res.json()
        ).then(
            () => {
                props.history.push('/exam-overview')
            }
        )
    }

    return (
        <div className="edit-exam">
            <div className="edit-exam-header-box">
                <Link to="/exam-overview"><ion-icon name="arrow-back-outline"></ion-icon> Back</Link>
                <div className="edit-exam-header">
                    <h1>Pas hier de velden die je wil veranderen aan.</h1>
                </div>
            </div>
            <div className="edit-exam-body-box">
                {dataLoaded ?
                    <div className="edit-exam-body">
                        <input className="name" type="hidden" value={exam.examID}></input>
                        <form onSubmit={formik.handleSubmit}>
                            {formik.errors.examName &&
                                <div className="errormsg">
                                    {formik.errors.examName}
                                </div>}
                            <div className="edit-exam-body-element">
                                <label>Naam van het examen:</label>
                                <input id="examName" name="examName" type="text" onChange={formik.handleChange} value={formik.values.examName}></input>
                            </div>
                            <div className="edit-exam-body-element">
                                <button type="submit"><span>Pas aan</span></button>
                            </div>
                        </form>
                    </div>
                    :
                    <div className="edit-exam-footer">
                        <p>aan het laden</p>
                    </div>
                }
            </div>
        </div>
    )
}

export default EditExamContent;