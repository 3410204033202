import React from 'react';
import Header from './Header';
import DeleteSubjectContent from './DeleteSubjectContent';
import Footer from './Footer';
import { useHistory, useParams } from 'react-router-dom';

function DeleteSubjectPage(props) {
    let {subjectID} = useParams();
    let history = useHistory();
    return (
        <div>
            <Header {...props} />
            <DeleteSubjectContent history={history} subjectID={subjectID}/>
            <Footer />
        </div>
    )
}

export default DeleteSubjectPage;