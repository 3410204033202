import React from 'react';
import Header from './Header';
import QuestionQuestionOverviewContent from './QuestionQuestionOverviewContent';
import Footer from './Footer';
import { useParams } from 'react-router-dom';

function QuestionQuestionOverviewPage(props) {
    let {subjectID} = useParams();
    return (
        <div>
            <Header {...props} />
            <QuestionQuestionOverviewContent subjectID={subjectID} />
            <Footer />
        </div>
    )
}

export default QuestionQuestionOverviewPage;