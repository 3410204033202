import React from 'react';
import Header from './Header';
import UserOverviewContent from './UserOverviewContent';
import Footer from './Footer';
import { useHistory } from 'react-router-dom';

function UserOverviewPage(props) {
    let history = useHistory();
    return (
        <div>
            <Header {...props} />
            <UserOverviewContent history={history} {...props} />
            <Footer />
        </div>
    )
}

export default UserOverviewPage