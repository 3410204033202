import React from 'react';
import {
    Switch,
    Route,
    withRouter,
    Redirect,
} from "react-router-dom";
import cookie from 'react-cookies';
import LoginPage from './LoginPage';
import WelcomePage from './WelcomePage';
import SubjectPage from './SubjectPage';
import ExamPage from './ExamPage';
import MadeMistakesPage from './MadeMistakesPage';
import RegistrationPage from './RegistrationPage';
import ContactPage from './ContactPage';
import AccountPage from "./AccountPage";
import AdminPage from './AdminPage';
import QuestionOverviewPage from './QuestionOverviewPage';
import QuestionQuestionOverviewPage from './QuestionQuestionOverviewPage';
import AddQuestionPage from "./AddQuestionPage";
import EditQuestionPage from "./EditQuestionPage";
import DeleteQuestionPage from "./DeleteQuestionPage";
import SubjectOverzichtPage from './SubjectOverzichtPage';
import AddSubjectPage from './AddSubjectPage';
import EditSubjectPage from './EditSubjectPage';
import DeleteSubjectPage from './DeleteSubjectPage';
import ExamOverviewPage from './ExamOverviewPage';
import ExamExamOverviewPage from './ExamExamOverviewPage';
import AddExamPage from './AddExamPage';
import EditExamPage from './EditExamPage';
import DeleteExamPage from './DeleteExamPage';
import SelectUsersForExamPage from './SelectUsersForExamPage';
import UserOverviewPage from './UserOverviewPage';
import PdfButtonPage from './PdfButtonPage';
import ExamChancesOverviewPage from './ExamChancesOverviewPage';
import ChangePasswordPage from './ChangePasswordPage';
import ForgotPasswordPage from './ForgotPasswordPage';
import PasswordResetPage from './PasswordResetPage';

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoggedIn: false,
            user: {}
        }
    }

    componentDidMount() {
        this.props.history.listen((location, action) => {
            this.checkIfTokenStillValid();
        });
    }

    checkIfTokenStillValid() {
        let jwt = cookie.load('jwt');
        const myRequest = new Request(`${process.env.REACT_APP_API_URL}/authenticate/is-expired`, {
            method: 'GET',
            headers: {
                Authorization: jwt
            }
        });
        fetch(myRequest).then(
            res => res.json()
        ).then(
            (result) => {
                if (result) {
                    cookie.remove('jwt', { path: '/' })

                }
                this.setState({
                    isLoggedIn: !result
                }
                )
            }
        ).then(
            () => this.getCurrentUser()
        )
    }

    getCurrentUser() {
        let jwt = cookie.load('jwt');
        if (jwt !== undefined) {
            const myRequest = new Request(`${process.env.REACT_APP_API_URL}/users/get-current-user`, {
                method: 'GET',
                headers: {
                    Authorization: jwt
                }
            });
            fetch(myRequest).then(
                (res) => {
                    if (!res.ok) {
                        throw Error(res);
                    }
                    return res.json();
                }
            ).then(
                (result) => {
                    this.setState({
                        user: result,
                        dataLoaded: true
                    })
                }
            ).catch(
                (res) => {

                }
            )
        }
    }

    render() {
        return (
            <div>
                <Switch >
                    {/*welcome page*/}
                    <Route path="/login/">
                        {!this.state.isLoggedIn ? <LoginPage /> : <Redirect to="/subjects" />}
                    </Route>

                    <Route path="/registration/">
                        <RegistrationPage />
                    </Route>

                    <Route path="/welcome-page/">
                        <WelcomePage />
                    </Route>

                    <Route path="/forgot-password/">
                        <ForgotPasswordPage />
                    </Route>

                    <Route path="/password-reset/:keyID/">
                        <PasswordResetPage />
                    </Route>

                    {/*header*/}
                    <Route path="/subjects/">
                       <SubjectPage user={this.state.user} />
                    </Route>

                    <Route path="/exam/:examID/">
                        {this.state.isLoggedIn ? <ExamPage /> : <Redirect to="/" />}
                    </Route>

                    <Route path="/made-mistakes/:examChanceID">
                        {this.state.isLoggedIn ? <MadeMistakesPage /> : <Redirect to="/" />}
                    </Route>

                    <Route path="/PdfButton/:examChanceID">
                        {this.state.isLoggedIn ? <PdfButtonPage user={this.state.user} /> : <Redirect to="/" />}
                    </Route>

                    <Route path="/contact/">
                        {this.state.isLoggedIn ? <ContactPage user={this.state.user} /> : <Redirect to="/" />}
                    </Route>

                    <Route path="/account/">
                        {this.state.isLoggedIn ? <AccountPage user={this.state.user} /> : <Redirect to="/" />}
                    </Route>

                    <Route path="/exam-chances-overview/:userID">
                        {this.state.isLoggedIn ? <ExamChancesOverviewPage user={this.state.user} /> : <Redirect to="/" />}
                    </Route>

                    <Route path="/change-password/:userID">
                        {this.state.isLoggedIn ? <ChangePasswordPage user={this.state.user} /> : <Redirect to="/" />}
                    </Route>

                    <Route path="/admin/">
                        {this.state.isLoggedIn ? <AdminPage user={this.state.user} /> : <Redirect to="/" />}
                    </Route>

                    {/*question overview*/}
                    <Route path="/question-overview/">
                        {this.state.isLoggedIn ? <QuestionOverviewPage user={this.state.user} /> : <Redirect to="/" />}
                    </Route>

                    <Route path="/question-question-overview/:subjectID">
                        {this.state.isLoggedIn ? <QuestionQuestionOverviewPage user={this.state.user} /> : <Redirect to="/" />}
                    </Route>

                    <Route path="/add-question/">
                        {this.state.isLoggedIn ? <AddQuestionPage user={this.state.user} /> : <Redirect to="/" />}
                    </Route>

                    <Route path="/edit-question/:questionID">
                        {this.state.isLoggedIn ? <EditQuestionPage user={this.state.user} /> : <Redirect to="/" />}
                    </Route>

                    <Route path="/delete-question/:questionID">
                        {this.state.isLoggedIn ? <DeleteQuestionPage user={this.state.user} /> : <Redirect to="/" />}
                    </Route>

                    {/*subject overview*/}
                    <Route path="/subject-overzicht/">
                        {this.state.isLoggedIn ? <SubjectOverzichtPage user={this.state.user} /> : <Redirect to="/" />}
                    </Route>

                    <Route path="/add-subject/">
                        {this.state.isLoggedIn ? <AddSubjectPage user={this.state.user} /> : <Redirect to="/" />}
                    </Route>

                    <Route path="/edit-subject/:subjectID">
                        {this.state.isLoggedIn ? <EditSubjectPage user={this.state.user} /> : <Redirect to="/" />}
                    </Route>

                    <Route path="/delete-subject/:subjectID">
                        {this.state.isLoggedIn ? <DeleteSubjectPage user={this.state.user} /> : <Redirect to="/" />}
                    </Route>

                    {/*exam overview*/}
                    <Route path="/exam-overview/">
                        {this.state.isLoggedIn ? <ExamOverviewPage user={this.state.user} /> : <Redirect to="/" />}
                    </Route>

                    <Route path="/exam-exam-overview/:examID">
                        {this.state.isLoggedIn ? <ExamExamOverviewPage user={this.state.user} /> : <Redirect to="/" />}
                    </Route>

                    <Route path="/add-exam/">
                        {this.state.isLoggedIn ? <AddExamPage user={this.state.user} /> : <Redirect to="/" />}
                    </Route>

                    <Route path="/edit-exam/:examID">
                        {this.state.isLoggedIn ? <EditExamPage user={this.state.user} /> : <Redirect to="/" />}
                    </Route>

                    <Route path="/delete-exam/:examID">
                        {this.state.isLoggedIn ? <DeleteExamPage user={this.state.user} /> : <Redirect to="/" />}
                    </Route>

                    <Route path="/select-users-for-exam/:examID">
                        {this.state.isLoggedIn ? <SelectUsersForExamPage user={this.state.user} /> : <Redirect to="/" />}
                    </Route>

                    {/*user overview*/}
                    <Route path="/user-overview/">
                        {this.state.isLoggedIn ? <UserOverviewPage user={this.state.user} /> : <Redirect to="/" />}
                    </Route>

                    <Route exact path="/" >
                        <WelcomePage />
                    </Route>
                </Switch>
            </div>
        )
    }

}

export default withRouter(App);