import React, { useState } from 'react';
import { useFormik } from 'formik';
import axios from 'axios';

function PasswordResetContent(props) {
    const [error, setError] = useState("");
    const validate = values => {
        const errors = {};
        if (values.newPassword.length < 8) {
            errors.newPassword = 'Paswoord moet langer zijn dan 8 tekens.';
        }
        if (values.newPassword !== values.confirmPassword) {
            errors.confirmPassword = 'Paswoorden komen niet overeen.';
        }
        return errors;
    };

    const formik = useFormik({
        initialValues: {
            oldPassword: '',
            newPassword: '',
            confirmPassword: '',
        },
        validate,
        onSubmit: values => {
            changePasswordFunction(values)
        }
    })

    async function changePasswordFunction(values) {
        const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/users/reset-password/${props.keyID}/`,  JSON.stringify(values), {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
        data ? props.history.push('/') : setError("Er is iets fout gelopen, probeer opnieuw.");
    }
    return (
        <div className="forgot-password">

            <div className="forgot-password-header">
                <h1>Verander paswoord</h1>
            </div>

            <div className="forgot-password-content">
                {error !== "" && <p style={{color: "red"}}>{error}</p>}
                <form onSubmit={formik.handleSubmit}>
                    <div className="forgot-password-content-errors">
                        {formik.errors.newPassword &&
                            <div>{formik.errors.newPassword}</div>}
                        {formik.errors.confirmPassword &&
                            <div>{formik.errors.confirmPassword}</div>}
                    </div>
                    <div className="forgot-password-content-input">
                        <div>
                            <label for="newPassword">Niew paswoord</label>
                            <input id="newPassword" name="newPassword" type="password" onChange={formik.handleChange} value={formik.values.newPassword} />
                        </div>
                        <div>
                            <label for="confirmPassword">Bevestig nieuw paswoord</label>
                            <input id="confirmPassword" name="confirmPassword" type="password" onChange={formik.handleChange} value={formik.values.confirmPassword} />
                        </div>
                    </div>
                    <button type="submit">Verander paswoord</button>
                </form>
            </div>
        </div>
    )
}

export default PasswordResetContent