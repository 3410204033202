import React from 'react';
import ChangePasswordContent from './ChangePasswordContent';
import Header from './Header';
import Footer from './Footer';
import { useParams, useHistory } from 'react-router-dom';

function ChangePasswordPage(props) {
    let {userID} = useParams();
    let history = useHistory();
    return (
        <div>
            <Header {...props} />
            <ChangePasswordContent history={history} {...props} userID={userID}/>
            <Footer />
        </div>
    )
}

export default ChangePasswordPage