import React from 'react';
import cookie from 'react-cookies';

class DeleteExamContent extends React.Component {
    constructor(props) {
        super(props);
        this.deleteFunction = this.deleteFunction.bind(this);
        this.cancelFunction = this.cancelFunction.bind(this);
    }

    deleteFunction() {
        let jwt = cookie.load('jwt');
        fetch(`${process.env.REACT_APP_API_URL}/exams/${this.props.examID}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${jwt}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(
            res => res.text()
        ).then(
            () => {
                this.props.history.push('/exam-overview')
            }
        )
    }

    cancelFunction() {
        this.props.history.push('/exam-overview')
    }

    render() {
        return (
            <div className="delete-exam">
                <p>Bent u zeker dat u dit examen wil verwijderen?</p>
                <div className="delete-exam-body">
                    <button onClick={() => this.deleteFunction()}><span>Verwijder</span></button>
                    <button onClick={() => this.cancelFunction()}><span>Annuleer</span></button>
                </div>
            </div>
        )
    }
}

export default DeleteExamContent;