import React from 'react';
import ExamChancesOverviewContent from './ExamChancesOverviewContent';
import Header from './Header';
import Footer from './Footer';
import { useParams } from 'react-router-dom';

function ExamChancesOverviewPage(props) {
    let {userID} = useParams();
    return (
        <div>
            <Header {...props} />
            <ExamChancesOverviewContent {...props} userID={userID}/>
            <Footer />
        </div>
    )
}

export default ExamChancesOverviewPage