import React from 'react';
import Footer from './Footer';
import WelcomeHero from './WelcomeHero';

function WelcomePage(props) {
    return (
        <div>
            <WelcomeHero />
            <Footer />
        </div>
    )
}

export default WelcomePage