import axios from 'axios';
import React from 'react';
import cookie from 'react-cookies';
import { Link } from 'react-router-dom';
import Hypnosis from "react-cssfx-loading/lib/Hypnosis";

class MadeMistakesContent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            examChance: {},
            currentSubject: {},
            madeMistakes: [],
            dataLoaded: false
        }
        this.changeCurrentSubject = this.changeCurrentSubject.bind(this);
    }

    async componentDidMount() {
        let jwt = cookie.load('jwt');
        const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/exam-chances/get-exam-chance/${this.props.examChanceID}`, {
            headers: {
                'Authorization': `Bearer ${jwt}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });
        let tempList;
        if (data.madeMistakes !== undefined) {
            tempList = data.madeMistakes.sort((a, b) => a.questionNumber >= b.questionNumber ? 1 : -1)
        }
        data.scores.sort(this.orderSubjects);
        this.setState({
            examChance: data,
            currentSubject: { id: data.scores[0].subjectID, name: data.scores[0].subjectName},
            madeMistakes: tempList,
            dataLoaded: true
        })
    }

    orderSubjects( a, b ){
        if ( a.subjectName.toLowerCase() < b.subjectName.toLowerCase()){
            return -1;
        }
        if ( a.subjectName.toLowerCase() > b.subjectName.toLowerCase()){
            return 1;
        }
        return 0;
        }

    changeCurrentSubject(subject) {
        this.setState({
            currentSubject: subject
        })
    }

    render() {
        return (
            <div className="made-mistakes">
                <Link className="go-back" to="/subjects"><ion-icon name="arrow-back-outline"></ion-icon>Terug naar overzicht</Link>
                <h1>Overzicht {this.state.examChance.date} gemaakte fouten {this.state.examChance.examID !== undefined && this.state.examChance.examName !== null && <Link className="pdfbutton" to={"/PdfButton/" + this.props.examChanceID}><img alt="" src="/images/downloadPdf.png"></img></Link>}</h1>
                {this.state.dataLoaded ?
                    <div>
                        <p>{this.state.currentSubject.name}</p>
                        {
                            this.state.examChance.scores !== undefined &&
                            this.state.examChance.scores.map((score, index) => (
                                <div className="made-mistakes-subject-button" key={index}>
                                    <p><button onClick={() => this.changeCurrentSubject({ id: score.subjectID, name: score.subjectName})}>{score.subjectName}</button> {score.score}/{score.maxScore}</p>
                                </div>
                            ))
                        }

                        {this.state.madeMistakes !== undefined &&
                            this.state.madeMistakes.map((mistake, index) => (
                                mistake.subjectID === this.state.currentSubject.id &&
                                <div key={index} className="made-mistakes-content">
                                    <div className="made-mistakes-content-vraag">
                                        <p>Vraag {mistake.questionNumber}:    {mistake.question}</p>
                                    </div>
                                    <div className="made-mistakes-content-j-f">
                                        <p className="fout">fout:     {mistake.answer}</p>
                                        <p className="juist">juist:    {mistake.correctAnswer}</p>
                                    </div>
                                    <div className="made-mistakes-content-reden">
                                        <p className="reden">{mistake.explanation}</p>
                                    </div>
                                    {mistake.image !== null &&
                                        <img alt="" src={mistake.image.fileDownloadUrl} />
                                    }
                                </div>

                            ))
                        }
                    </div>
                    :
                    <div className="loader-animation">
                        <Hypnosis  color="#3399ff" width="50px" height="50px" duration="2s" />
                    </div>
                }
            </div>
        )
    }
}

export default MadeMistakesContent;