import React, { useState } from 'react';
import cookie from 'react-cookies';
import { useFormik } from 'formik';
import axios from 'axios';

function ChangePasswordContent(props) {
    const [error, setError] = useState("");

    const validate = values => {
        const errors = {};
        if (values.newPassword.length < 8) {
            errors.newPassword = 'Paswoord moet langer zijn dan 8 tekens.';
        }
        if (values.newPassword !== values.confirmPassword) {
            errors.confirmPassword = 'Paswoorden komen niet overeen.';
        }
        return errors;
    };

    const formik = useFormik({
        initialValues: {
            oldPassword: '',
            newPassword: '',
            confirmPassword: '',
        },
        validate,
        onSubmit: values => {
            changePasswordFunction(values)
        }
    })

    async function changePasswordFunction(values) {
        let jwt = cookie.load('jwt');
        const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/users/change-password/${props.user.userID}`,  JSON.stringify(values), {
            headers: {
                'Authorization': `Bearer ${jwt}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
        data ? props.setShowPasswordModal(false) : setError("Er is iets fout gelopen, probeer opnieuw.");
    }


    return (
        <>
            <div className="accountForm-header">
                <h1>Verander paswoord</h1>
            </div>

            <div className="accountForm-content">
                {error !== "" && <p style={{color: "red"}}>{error}</p>}
                <form onSubmit={formik.handleSubmit}>
                    {(formik.errors.newPassword || formik.errors.confirmPassword) &&
                        <div className="errors">
                            {formik.errors.newPassword &&
                                <div>{formik.errors.newPassword}</div>}
                            {formik.errors.confirmPassword &&
                                <div>{formik.errors.confirmPassword}</div>}
                        </div>
                    }
                    <div className='fields'>
                        <div className='fields-input'>
                            {/* <label for="oldPassword">Oud paswoord</label> */}
                            <input id="oldPassword" name="oldPassword" type="password" placeholder='Oud paswoord' onChange={formik.handleChange} value={formik.values.oldPassword} />
                        </div>
                        <div className='fields-input'>
                            {/* <label for="newPassword">Nieuw paswoord</label> */}
                            <input id="newPassword" name="newPassword" type="password" placeholder='Nieuw paswoord' onChange={formik.handleChange} value={formik.values.newPassword} />
                        </div>
                        <div className='fields-input'>
                            {/* <label for="confirmPassword">Bevestig nieuw paswoord</label> */}
                            <input id="confirmPassword" name="confirmPassword" type="password" placeholder='Bevestig nieuw paswoord' onChange={formik.handleChange} value={formik.values.confirmPassword} />
                        </div>
                    </div>

                    <button className={props.classname} type="submit" ><span>Verander paswoord</span></button>
                </form>
            </div>
        </>
    )
}

export default ChangePasswordContent