import React, { useEffect } from 'react';
import cookie from 'react-cookies';
import Header from './Header';
import SubjectInfo from './SubjectInfo';
import { useHistory} from 'react-router-dom';
import Footer from './Footer';
import axios from 'axios';


function SubjectPage(props) {
    let history = useHistory();
    
    useEffect(() => {
        let jwt = cookie.load('jwt');
        if (jwt) {
           checkIfTokenValid(jwt);
        } else {
            history.push('/');
        }
    });

    async function checkIfTokenValid(jwt) {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/authenticate/is-expired`, {
            headers: {
                "Authorization": jwt,
            }
        })
        if (response.data === true) {
            history.push('/');
        }
    }
    return (
        <div>
            <Header {...props} />
            <SubjectInfo  history={history} {...props}/>
            <Footer />
        </div>
    )
}

export default SubjectPage