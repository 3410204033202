import React from 'react';
import Header from './Header';
import AdminContent from './AdminContent';
import Footer from './Footer';

function AdminPage(props) {
    return (
        <div>
            <Header  {...props} />
            <AdminContent />
            <Footer />
        </div>
    )
}

export default AdminPage;