import axios from 'axios';
import React from 'react';
import cookie from 'react-cookies';

class DeleteSubjectContent extends React.Component {
    constructor(props) {
        super(props);
        this.deleteFunction = this.deleteFunction.bind(this);
        this.cancelFunction = this.cancelFunction.bind(this);
    }

    async deleteFunction() {
        let jwt = cookie.load('jwt');
        await axios.delete(`${process.env.REACT_APP_API_URL}/subjects/${this.props.subjectID}`, { 
            headers: {
                'Authorization': `Bearer ${jwt}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });
        this.props.history.push('/subject-overzicht')
    }

    cancelFunction() {

        this.props.history.push('/subject-overzicht')
    }

    render() {
        return (
            <div className="delete-subject">
                <p>Bent u zeker dat u dit onderdeel wil verwijderen?</p>
                <div className="delete-subject-body">
                    <button onClick={() => this.deleteFunction()}><span>Verwijder</span></button>
                    <button onClick={() => this.cancelFunction()}><span>Annuleer</span></button>
                </div>
            </div>
        )
    }
}

export default DeleteSubjectContent;