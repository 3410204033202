import React from 'react';
import cookie from 'react-cookies';
import { Link } from 'react-router-dom';

class ExamExamOverviewContent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            exam: {},
            currentSubject: {},
            dataLoaded: false
        }
        this.changeCurrentSubject = this.changeCurrentSubject.bind(this);
    }

    componentDidMount() {
        let jwt = cookie.load('jwt');
        fetch(`${process.env.REACT_APP_API_URL}/exams/get-exam-exam-overview/${this.props.examID}`, {
            method: 'GET',
            cache: 'no-store',
            headers: {
                'Authorization': `Bearer ${jwt}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(
            res => res.json()
        ).then(
            (result) => {
                result.subjects !== null &&
                    this.setState({
                        exam: result,
                        currentSubject: result.subjects[0],
                        dataLoaded: true
                    })
            }
        )
    }

    changeCurrentSubject(subject) {
        this.setState({
            currentSubject: subject
        })
    }

    render() {
        return (
            <div className="exam-exam">
                <Link className="go-back" to="/exam-overview"><ion-icon name="arrow-back-outline"></ion-icon> Back</Link>
                <h2>Overzicht Examens</h2>
                {this.state.dataLoaded ?
                    <div className="exam-exam-content">
                        <div className="exam-exam-content-header">
                            <div className="exam-exam-content-header-exam">
                                <p>Naam examen: </p>
                                <p>{this.state.exam.examName}</p>
                            </div>
                            <div className="exam-exam-content-header-subjects">
                                <p id="onderdelen">Onderdelen:</p>
                                {this.state.exam.subjects.map((subject, i) => (
                                    <button key={`subject-${i}`} onClick={() => this.changeCurrentSubject(subject)}><span>{subject}</span></button>
                                ))
                                }
                            </div>
                            <div className="exam-exam-content-header-current">
                                <ion-icon name="arrow-forward-outline"></ion-icon>
                                <p>{this.state.currentSubject}</p>
                            </div>
                        </div>
                        <div>
                            {this.state.currentSubject !== null &&
                                <div>
                                    {this.state.exam.questions.map((question, index) => (
                                        question.subject === this.state.currentSubject &&
                                        <div key={question.question.questionID} className="test">
                                            <p className={question.difficulty === "EASY" ? "easy" : question.difficulty === "MEDIUM" ? "medium" : "hard"}><span>{index + 1}</span><p className="difficulty">{question.question}</p></p>
                                            <Link to={"/edit-question/" + question.questionID}><ion-icon name="create-outline"></ion-icon></Link>
                                            <Link to={"/delete-question/" + question.questionID}><ion-icon name="trash-outline"></ion-icon></Link>
                                        </div>
                                    ))
                                    }
                                </div>
                            }
                        </div>

                    </div>
                    :
                    <p>Aan het laden</p>
                }
            </div>
        )
    }
}

export default ExamExamOverviewContent;