import React from 'react';
import Header from './Header';
import QuestionOverviewContent from './QuestionOverviewContent';
import Footer from './Footer';

function QuestionOverviewPage(props) {
    return (
        <div>
            <Header  {...props} />
            <QuestionOverviewContent />
            <Footer />
        </div>
    )
}

export default QuestionOverviewPage;