import React from 'react';
import Header from './Header';
import EditQuestionContent from './EditQuestionContent';
import Footer from './Footer';
import { useHistory, useParams } from 'react-router-dom';

function EditQuestionPage(props) {
    let {questionID} = useParams();
    let history = useHistory();
    return (
        <div>
            <Header  {...props} />
            <EditQuestionContent history={history} questionID={questionID}/>
            <Footer />
        </div>
    )
}

export default EditQuestionPage;