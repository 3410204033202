import axios from 'axios';
import React from 'react';
import cookie from 'react-cookies';
import { Link } from 'react-router-dom';
import Hypnosis from "react-cssfx-loading/lib/Hypnosis";

class QuestionQuestionOverviewContent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            questions: [],
            subject: "",
            dataLoaded: false
        }
    }

    async componentDidMount() {
        let jwt = cookie.load('jwt');
        const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/subjects/get-questions/${this.props.subjectID}`, {
            cache: 'no-store',
            headers: {
                'Authorization': `Bearer ${jwt}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });
        this.setState({
            questions: data.questions,
            subject: data.subjectName,
            dataLoaded: true
        })
    }
    render() {
        return (
            <div className="question-question-overview">
                <div className="question-question-overview-header-box">
                    <Link className="go-back" to="/question-overview"><ion-icon name="arrow-back-outline"></ion-icon> Back</Link>
                    <div className="question-question-overview-header">
                        <h1>Beheer vragen</h1>
                        <p>{this.state.subject}</p>
                    </div>
                </div>
                <div className="question-question-overview-body-box">
                    <div className="question-question-overview-body">
                        {this.state.dataLoaded ?
                            this.state.questions.map((element, index) => (
                                <div className="question-question-overview-body-content" key={element.questionID}>

                                    <div className="question-question-overview-body-content-index">
                                        <p>{index + 1}</p>
                                    </div>

                                    <div className="question-question-overview-body-content-question-answer">
                                        <div>
                                            <p className="question">{element.question}</p>
                                            <p className="answers">Antwoorden:</p>
                                           
                                            {element.possibleAnswers.map((answer, index) => (
                                                <div className="abcd" key={index}>
                                                    {index === 0 ? <p className={element.correctAnswer === answer ? "correct-answer" : "answer"}><p className="test">A) </p> <span>{answer}</span></p>
                                                        : index === 1 ? <p className={element.correctAnswer === answer ? "correct-answer" : "answer"}><p className="test">B) </p> <span>{answer}</span> </p>
                                                            : index === 2 ? <p className={element.correctAnswer === answer ? "correct-answer" : "answer"}><p className="test">C) </p> <span>{answer}</span></p>
                                                                : index === 3 ? <p className={element.correctAnswer === answer ? "correct-answer" : "answer"}><p className="test">D) </p> <span>{answer}</span></p>
                                                                    : <p></p>
                                                    }
                                                </div>
                                            ))
                                            }
                                        </div>
                                        {element.explanation !== "" && element.explanation !== null &&
                                            <div>
                                                <p className="reason">Reden:</p>
                                                <p>{element.explanation}</p>
                                            </div>
                                        }
                                        {element.image != null &&
                                            <img alt="" src={element.image.fileDownloadUrl} />
                                        }
                                    </div>
                                    <div className="question-question-overview-body-content-edit-delete-parent">
                                        <div className="question-question-overview-body-content-checkboxes">
                                            {element.practiceQuestion ?
                                                <p>🗹 Oefenvraag</p>
                                                :
                                                <p>𐄂 Oefenvraag</p>
                                            }
                                            {element.exams.length > 0 &&
                                                element.exams.map((exam) =>
                                                    exam.examName !== null &&
                                                    <p id="dynamic-exam" key={exam.examID}><p id="checkbox">🗹</p> <span>{exam.examName}</span></p>
                                                )}

                                            {element.difficulty === "EASY" &&
                                                <div>
                                                    <p className="">🗹 Makkelijk</p>
                                                    <p className="">𐄂 Gemiddeld</p>
                                                    <p className="">𐄂 Moeilijk</p>
                                                </div>
                                            }

                                            {element.difficulty === "MEDIUM" &&
                                                <div>
                                                    <p className="">𐄂 Makkelijk</p>
                                                    <p className="">🗹 Gemiddeld</p>
                                                    <p className="">𐄂 Moeilijk</p>
                                                </div>
                                            }

                                            {element.difficulty === "HARD" &&
                                                <div>
                                                    <p className="">𐄂 Makkelijk</p>
                                                    <p className="">𐄂 Gemiddeld</p>
                                                    <p className="">🗹 Moeilijk</p>
                                                </div>
                                            }

                                            <div className="question-question-overview-body-content-edit-delete">
                                                <Link to={"/edit-question/" + element.questionID}><ion-icon name="create-outline"></ion-icon></Link>
                                                <Link to={"/delete-question/" + element.questionID}><ion-icon name="trash-outline"></ion-icon></Link>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                            ))
                            :
                            <div className="loader-animation">
                                <Hypnosis  color="#3399ff" width="50px" height="50px" duration="2s" />
                            </div>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default QuestionQuestionOverviewContent;