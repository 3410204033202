import React from 'react';
import ExamContent from './ExamContent';
import { useHistory, useParams } from 'react-router-dom';

function ExamPage(props) {
    let {examID} = useParams();
    let history = useHistory();
    return (
        <div>
            <ExamContent history={history} examID={examID} {...props} />
        </div>
    )
}

export default ExamPage;