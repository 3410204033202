import axios from 'axios';
import React from 'react';
import cookie from 'react-cookies';
import { Link } from 'react-router-dom';
import Hypnosis from "react-cssfx-loading/lib/Hypnosis";
import ReactTooltip from 'react-tooltip';

class SubjectOverzichtContent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            subjects: [],
            dataLoaded: false
        }
    }

    async componentDidMount() {
        let jwt = cookie.load('jwt');
        const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/subjects/get-subjects-viewmodel`, {
            headers: {
                'Authorization': `Bearer ${jwt}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });
        this.setState({
            subjects: data,
            dataLoaded: true
        })
    }
    render() {
        return (
            <div className="subjectOverzicht">
                <div className="subjectOverzicht-header-box">
                    <Link className="go-back" to="/admin"><ion-icon name="arrow-back-outline"></ion-icon> Back</Link>
                    <div className="subjectOverzicht-header">
                        <h1>Beheer onderdelen</h1>
                    </div>
                </div>

                <div className="subjectOverzicht-header-index-box">
                    {this.state.dataLoaded ?
                        <div>
                            <div className="subjectOverzicht-header-index">
                                <p>Onderwerp</p>
                                <p>Aantal vragen</p>
                                <p>Duur</p>
                                <p>Minimum score</p>
                                <p></p>

                            </div>

                            <div className="subjectOverzicht-body">
                                    {this.state.subjects.map((element) => (
                                        <div className="subjectOverzicht-body-content" key={element.subjectID}>
                                            <div className="subjectOverzicht-body-content-p1">
                                                {element.name.length > 20 ? <p data-tip data-for={element.name}>{element.name.substr(0, 20) + "..."}</p> : <p>{element.name}</p>}
                                                <ReactTooltip id={element.name} effect='solid'>
                                                    <span>{element.name}</span>
                                                </ReactTooltip>
                                            </div>
                                            <div className="subjectOverzicht-body-content-p2">
                                                <p>{element.amountOfQuestionsPerExam} vragen</p>
                                            </div>
                                            <div className="subjectOverzicht-body-content-p3">
                                                <p>{element.amountOfMinutesPerExam} minuten</p>
                                            </div>
                                            <div className="subjectOverzicht-body-content-p4">
                                                <p> {element.minimumScorePerExam}</p>
                                            </div>
                                            <div className="subjectOverzicht-body-content-l1">
                                                <Link to={"/edit-subject/" + element.subjectID}><ion-icon name="create-outline"></ion-icon></Link>
                                                <Link to={"/delete-subject/" + element.subjectID}><ion-icon name="trash-outline"></ion-icon></Link>
                                            </div>
                                        </div>
                                    ))}
                            </div>
                        
                            <div className="subjectOverzicht-footer">
                                <button><Link to="add-subject"><span>Voeg toe</span></Link></button>
                            </div>
                        </div>
                        :
                        <div className="loader-animation">
                            <Hypnosis  color="#3399ff" width="50px" height="50px" duration="2s" />
                        </div>
                    }
                </div>
            </div>
        )
    }
}

export default SubjectOverzichtContent;