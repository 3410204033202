import React, { PureComponent } from 'react';
import jsPDF from 'jspdf';
import cookie from 'react-cookies';
import axios from 'axios';
import Hypnosis from "react-cssfx-loading/lib/Hypnosis";

class PdfButton extends PureComponent {
    constructor(props) {
        super(props)
        this.cancelFunction = this.cancelFunction.bind(this);
        this.state = {
            examChance: {},
            dataLoaded: false
        }
    }

    async componentDidMount() {
        let jwt = cookie.load('jwt');
        const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/exam-chances/get-exam-chance-for-pdf/${this.props.examChanceID}`, {
            headers: {
                'Authorization': `Bearer ${jwt}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });
        this.setState({
            examChance: data,
            dataLoaded: true
        })
    }

    formatScoreInProcent(score, maxScore) {
        let procent = ((score / maxScore) * 100).toFixed(2);
        if (procent.substring(procent.length - 2, procent.length) === "00") {
            return procent.substring(0, procent.length -  3) + "%";
        } 
        return procent + "%";
    }

    PdfButton = () => {
        var doc = new jsPDF('p', 'pt');

        doc.setFontType('normal')
        doc.setFontSize('10')
        doc.text(60, 100, "Liga van Vlaamse zweefvliegclubs")
        doc.text(60, 120, "BE.DTO.130")
        doc.text(60, 140, "Drieskensstraat 46")
        doc.text(60, 160, "2300 Turnhout")
        doc.text(60, 220, "Proefexamen theorie voor het behalen van SPL – Sailplane Pilot License")
        doc.text(60, 250, `Naam: ${this.state.examChance.firstName.charAt(0).toUpperCase() + this.state.examChance.firstName.slice(1) + " "
        + this.state.examChance.lastName.charAt(0).toUpperCase() + this.state.examChance.lastName.slice(1)}`)
        doc.text(60, 265, `Datum examen: ${this.state.examChance.date}`)
        doc.text(60, 280, `Club: ${this.state.examChance.club}`)
        doc.text(60, 325, `Behaald resultaat voor het examen ${this.state.examChance.examName}:`)

        //eerste kolom
        doc.text(60, 350, 'Onderwerp')

        
            this.state.examChance.scores !== undefined &&
                this.state.examChance.scores.map((score, index) => (
                    doc.text(60, 380 + (index * 15), score.subjectName)
                ))
        

        //middelste kolom
        doc.text(280, 350, 'vereiste minimun')
        
            this.state.examChance.scores !== undefined &&
                this.state.examChance.scores.map((score, index) => (
                    doc.text(310, 380 + (index * 15), '75%')
                ))
        

        //laaste kolom
        doc.text(440, 350, 'Behaald resultaat')
        
            this.state.examChance.scores !== undefined &&
                this.state.examChance.scores.map((score, index) => (
                    doc.text(440, 380 + (index * 15), score.score === -1 ? "vrijgesteld" : this.formatScoreInProcent(score.score, score.maxScore)),
                    doc.text(500, 380 + (index * 15), `${score.passed ? "Geslaagd" : score.score === -1 ? "Geslaagd" : "Niet geslaagd"}`)
                ))
        

        
            this.state.examChance.isPassed ?
                doc.text(60, 550, 'Je bent geslaagd voor je proefexamen je kan aangemeld worden voor het theoretisch examen bij DGLV. ')
                :
                doc.text(60, 550, `Je bent niet geslaagd voor ${this.state.examChance.amountOfSubjectsFailed} ${this.state.examChance.amountOfSubjectsFailed === 1 ? "vak" : "vakken"}.\nJe moet enkel deze vakken herkansen, laat je bijscholen en studeer goed alvorens je aan te melden\nvoor het herexamen.`)
        

        doc.text(60, 640, "Handtekening toezichthoudende FI")
        doc.text(60, 680, "………………………………………")

        doc.text(60, 750, "Liga van Vlaamse Zweefvliegclubs – Opleidingsorganisatie BE.DTO.130 ")

        //pdf saven
        doc.save("Resultaat.pdf");
    }

    cancelFunction() {
        this.props.history.push(`/made-mistakes/${this.props.examChanceID}`)
    }

    render() {
        return (
            <div className="pdf-button">
                {this.state.dataLoaded ?
                    <div>
                        <p>Bent u zeker dat u de PDF wilt downloaden?</p>
                        <div className="pdf-button-body">
                            <button onClick={this.PdfButton}><span>Download PDF</span></button>
                            <button onClick={() => this.cancelFunction()}><span>Overzicht fouten</span></button>
                        </div>
                    </div>
                    :
                    <div className="loader-animation">
                        <Hypnosis  color="#3399ff" width="50px" height="50px" duration="2s" />
                    </div>

                }

            </div>
        )
    }
}
export default PdfButton
