import React from 'react';
import MadeMistakesContent from './MadeMistakesContent';
import { useParams } from 'react-router-dom';

function MadeMistakesPage(props) {
    let {examChanceID} = useParams();
    return (
        <div>
            <MadeMistakesContent examChanceID={examChanceID} />
        </div>
    )
}

export default MadeMistakesPage;