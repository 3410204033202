import axios from 'axios';
import React from 'react';
import cookie from 'react-cookies';
import { Link } from 'react-router-dom';
import Hypnosis from "react-cssfx-loading/lib/Hypnosis";
import ReactTooltip from 'react-tooltip';

class UserOverviewContent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            users: [],
            allUsers: [],
            allClubs: [],
            activeUsers: true,
            dataLoaded: false
        }
    }

    async componentDidMount() {
        let jwt = cookie.load('jwt');
        const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/users/get-all-users`, {
            headers: {
                'Authorization': `Bearer ${jwt}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });
        let allClubs = [];
        data.forEach(element => {
            if (!allClubs.includes(element.club)) allClubs.push(element.club);
        })
        allClubs = allClubs.map(club => ({
            club,
            open: false,
        }))
        this.setState({
            users: data.filter(e => e.active),
            allUsers: data,
            allClubs: allClubs,
            active: true,
            dataLoaded: true
        })
    }

    async getAllUsers(onlyActiveUsers) {
        this.setState({
            users: onlyActiveUsers ? this.state.allUsers.filter(e => e.active) : this.state.allUsers,
            activeUsers: onlyActiveUsers
        })
    }

    async swapRole(username, onlyActiveUsers) {
        let jwt = cookie.load('jwt');
        const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/users/swap-role/${username}`, {
            headers: {
                'Authorization': `Bearer ${jwt}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        });
        this.setState({
            users: onlyActiveUsers ? data.filter(e => e.active) : data,
            allUsers: data,
            active: onlyActiveUsers
        })
    }

    async changeActive(userID, onlyActiveUsers) {
        let jwt = cookie.load('jwt');
        const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/users/change-active/${userID}`, {
            headers: {
                'Authorization': `Bearer ${jwt}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        });
        this.setState({
            users: onlyActiveUsers ? data.filter(e => e.active) : data,
            allUsers: data,
            active: onlyActiveUsers
        })
    }

    async changePPL(userID, onlyActiveUsers) {
        let jwt = cookie.load('jwt');
        const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/users/change-ppl/${userID}`, {
            headers: {
                'Authorization': `Bearer ${jwt}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        });
        this.setState({
            users: onlyActiveUsers ? data.filter(e => e.active) : data,
            allUsers: data,
            active: onlyActiveUsers
        })
    }

    openClubTab(index) {
        let allClubsCopy = this.state.allClubs;
        allClubsCopy[index].open = !allClubsCopy[index].open;
        this.setState({
            allClubs: allClubsCopy
        })
    }

    render() {
        return (
            <div className="user-overview">
                <div className="user-overview-header-box">
                    <Link className="go-back" to="/admin"><ion-icon name="arrow-back-outline"></ion-icon> Back</Link>
                    <h1>Account Beheer</h1>
                    <button onClick={() => this.getAllUsers(!this.state.activeUsers)}>{this.state.activeUsers ? "alle gebruikers" : "actieve gebruikers"}</button>
                </div>
                <div className="user-overview-body-box">
                    {this.state.dataLoaded ?
                        <div className="user-overview-body">
                            {this.state.allClubs.map((club, index) => (
                                    <div key={index}>
                                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center"}}>
                                            <div key="index" style={{ marginRight: "10px" }}> {club.club} </div>
                                            <ion-icon name="chevron-down-outline" onClick={() => this.openClubTab(index)}></ion-icon>
                                        </div>
                                        {club.open &&
                                            <div>
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <th>email</th>
                                                            <th>voornaam</th>
                                                            <th>naam</th>
                                                            <th>username</th>
                                                            <th>actief</th>
                                                            <th>PPL</th>
                                                            {this.props.user.authorities && this.props.user.authorities[0].authority === "admin" && <th>mod</th>}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.users.map((user, index) => (
                                                            user.authorities[0].authority !== "admin" && user.club === club.club &&   
                                                                <tr key={index}> 
                                                                    <td>
                                                                        {user.hasResults ? <p><Link to={"/exam-chances-overview/" + user.userID} data-tip data-for={user.email}>{user.email}</Link></p> : <p data-tip data-for={user.email}>{user.email}</p>}
                                                                        <ReactTooltip id={user.email} type='dark' effect='solid'>
                                                                            <span>{user.email}</span>
                                                                        </ReactTooltip>
                                                                    </td>
                                                                    <td><p>{user.firstName}</p></td>
                                                                    <td><p>{user.lastName}</p></td>
                                                                    <td><p>{user.username}</p></td>
                                                                    <td>
                                                                        <span onClick={() => this.changeActive(user.userID, this.state.activeUsers)}>{user.active ? <ion-icon name="checkbox-outline"></ion-icon> : <ion-icon name="square-outline"></ion-icon>}</span>
                                                                    </td>
                                                                    <td>
                                                                        <span  onClick={() => this.changePPL(user.userID, this.state.activeUsers)}>{user.hasPPL ? <ion-icon name="checkbox-outline"></ion-icon> : <ion-icon name="square-outline"></ion-icon>}</span>
                                                                    </td>
                                                                    
                                                                        {this.props.user.authorities !== undefined ?
                                                                            this.props.user.authorities[0].authority === "admin" ?
                                                                                user.authorities[0].authority === "user" ?
                                                                                    <td><span onClick={() => this.swapRole(user.username, this.state.activeUsers)}><ion-icon name="square-outline"></ion-icon></span></td>
                                                                                    :
                                                                                    <td><span onClick={() => this.swapRole(user.username, this.state.activeUsers)}><ion-icon name="checkbox-outline"></ion-icon></span></td>
                                                                                :
                                                                                <></>
                                                                            :
                                                                            <></>
                                                                        }
                                                                </tr>
                                                        ))
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        }
                                    </div>
                            ))}
                            
                        </div>
                        :
                        <div className="loader-animation">
                            <Hypnosis  color="#3399ff" width="50px" height="50px" duration="2s" />
                        </div>
                    }
                </div>
            </div>
        )
    }
}

export default UserOverviewContent;