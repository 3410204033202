import React from 'react';
import cookie from 'react-cookies';
import axios from 'axios';
import ChangePasswordContent from './ChangePasswordContent';
import { Link } from 'react-router-dom';

class AccountContent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            club: this.props.user.club,
            showPasswordModal: false,
        }
        this.setShowPasswordModal = this.setShowPasswordModal.bind(this)
    }

    async changeClub(value){
        let jwt = cookie.load('jwt');
        await axios.post(`${process.env.REACT_APP_API_URL}/users/change-club/${this.props.user.userID}`, JSON.stringify({ clubName: value }), {
            headers: {
                'Authorization': `Bearer ${jwt}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
        this.props.history.push('/account')
    }

    setShowPasswordModal(value) {
        this.setState({
            showPasswordModal: value
        })
    }

    render() {
        return (
            <div className="account-page">
                <div className="account-page-message">
                    <h1>Account</h1>
                </div>

                <div className="account-page-message-content">
                    <ion-icon name="person"></ion-icon>
                    <p>Gebruikersnaam: {this.props.user.username}</p>
                    <p>Naam: {this.props.user.firstName}</p>
                    <p>Achternaam: {this.props.user.lastName}</p>
                    <p>E-mail: {this.props.user.email}</p>
                    <div className="account-page-message-content-club">
                        <p>Club:</p>
                        <select id="club" name="club" onChange={(event) => this.changeClub(event.target.value)} value={this.props.user.club}>
                            <option value="ACK">ACK</option>
                            <option value="ALB">ALB</option>
                            <option value="BZC">BZC</option>
                            <option value="DAC">DAC</option>
                            <option value="KAC">KAC</option>
                            <option value="KAZM">KAZM</option>
                            <option value="KFC">KFC</option>
                            <option value="KVDW">KVDW</option>
                            <option value="LUAC">LUAC</option>
                            <option value="LZC">LZC</option>
                            <option value="VZA">VZA</option>
                            <option value="VZP">VZP</option>
                            <option value="LV">LV</option>
                        </select>
                    </div>
                    <button onClick={() => this.setShowPasswordModal(true)}>Verander paswoord</button>
                            {
                                this.state.showPasswordModal &&
                                <div className="accountF">
                                    <div className="accountForm">
                                        <ChangePasswordContent setShowPasswordModal={this.setShowPasswordModal} classname={"accountForm-btn"} user={this.props.user} />
                                        <span onClick={() => this.setShowPasswordModal(false)} id='close'>&#10005;</span>
                                    </div>
                                </div>
                            }
                    <p><Link to={"/exam-chances-overview/" + this.props.user.userID}>mijn examens</Link></p>
                </div>
            </div>
        )
    }
}

export default AccountContent;