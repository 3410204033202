import axios from 'axios';
import React from 'react';
import cookie from 'react-cookies';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import Hypnosis from "react-cssfx-loading/lib/Hypnosis";

class ExamChancesOverviewContent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            examChances: [],
            firstName: "",
            lastName: "",
            dataLoaded: false
        }
    }

    async componentDidMount() {
        let jwt = cookie.load('jwt');
        const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/exam-chances/get-exam-chances-for-user/${this.props.userID}`, {
            headers: {
                'Authorization': `Bearer ${jwt}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
        
        let tempList = data.examChances.map(examChance => ({
            open: false, examChance
        }))
        tempList.forEach(examChance => {
            examChance.examChance.attempts.sort(this.orderAttempts);
            examChance.examChance.attempts.forEach(attempt => {
                attempt.scores.sort(this.orderSubjects);
            });
        });
        this.setState({
            examChances: tempList,
            firstName: data.firstName,
            lastName: data.lastName,
            dataLoaded: true
        })
    }

    orderSubjects( a, b ){
        if ( a.subjectName.toLowerCase() < b.subjectName.toLowerCase()){
            return -1;
        }
        if ( a.subjectName.toLowerCase() > b.subjectName.toLowerCase()){
            return 1;
        }
        return 0;
    }

    orderAttempts( a, b ){
        if ( a.attempt < b.attempt){
            return -1;
        }
        if ( a.attempt > b.attempt){
            return 1;
        }
        return 0;
    }

    openScoreTable(index) {
        let allExamChancesCopy = this.state.examChances;
        allExamChancesCopy[index].open = !allExamChancesCopy[index].open;
        this.setState({
            allClubs: allExamChancesCopy
        })
    }

    render() {
        return (
            <div className="exam-chances">
                {this.state.dataLoaded ?  this.state.examChances.length > 0 ? 
                <div className="exam-chances-content">
                    <div className="user-overview-table">
                        {this.props.user.userID !== this.props.userID && <Link className="go-back" to="/user-overview"><ion-icon name="arrow-back-outline"></ion-icon> Back</Link> }
                        <p>examens voor {this.state.firstName + " " + this.state.lastName} </p>
                    </div>
                    {this.state.examChances.map((element, index) => (
                        <div key={index}>
                            <div className="exam-chances-content-element">

                                    {element.examChance.examName === null ? <p className="name">Oefen Examen</p> : <p className="name">{element.examChance.examName}</p>}
                                    {element.examChance.examName && 
                                    <div className="exam-chances-content-element-button">
                                        {!element.open ? 
                                            <div className="exam-chances-content-element-button">
                                                <p>Bekijk resultaten</p>
                                                <ion-icon name="chevron-down-outline" onClick={() => this.openScoreTable(index)}></ion-icon>
                                            </div>   
                                            :
                                            <div className="exam-chances-content-element-button">
                                                <p>Verberg resultaten</p>
                                                <ion-icon name="chevron-up-outline" onClick={() => this.openScoreTable(index)}></ion-icon>
                                            </div>
                                        }
                                    </div>
                                    }
                            </div>
                            {element.examChance.examName && element.open &&
                            
                            <div className="exam-chances-content-individual-scores">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>attempt</th>
                                                {element.examChance.attempts[0].scores.map((subject, index) => (
                                                    <th key={index} data-tip data-for={subject.subjectName}>{subject.subjectName.substring(0, 5)}...
                                                        <ReactTooltip id={subject.subjectName} effect='solid'>
                                                        <span>{subject.subjectName}</span>
                                                        </ReactTooltip>
                                                    </th>
                                                ))}
                                                <th>TOTAAL</th>
                                                <th>Geslaagd</th>
                                                <th>PRINT</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {element.examChance.attempts.map((attempt, index) => (
                                                <tr key={index}>
                                                    <td>{attempt.attempt}</td>
                                                {attempt.scores.map((subject, index) => (
                                                    subject.score === -1 ? <td style={{backgroundColor: "#2c9722"}}>vrijgesteld</td> : <td style={subject.passed ? {backgroundColor: "#2c9722"} : {backgroundColor: "#ca2121"}}>{subject.score}/{subject.maxScore}</td>
                                                    ))}
                                                    <td style={attempt.isPassed ? {backgroundColor: "#2c9722"} : {backgroundColor: "#ca2121"}}>{attempt.score} / {attempt.maxScore}</td>
                                                    {attempt.isPassed ? <td style={{backgroundColor: "#2c9722"}}>Ja</td> : <td style={{backgroundColor: "#ca2121"}}>Nee</td>}
                                                    <td><Link className="pdfbutton" to={"/PdfButton/" + attempt.examChanceID}><img alt="" src="/images/downloadPdf.png"></img></Link></td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                               
                            </div>
                                }
                                <hr style={{ "width": "100%", "margin" : "auto"}}></hr>
                        </div>
                    ))}
                </div>
                : 
                <p>Je hebt nog geen examens gemaakt!</p>
            :
                <div className="loader-animation">
                    <Hypnosis  color="#3399ff" width="50px" height="50px" duration="2s" />
                </div>}
            </div>
        )
    }
}

export default ExamChancesOverviewContent;