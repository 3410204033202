import React from 'react';
import ContactContent from './ContactContent';
import Header from './Header';
import Footer from './Footer';

function ContactPage(props) {
    return (
        <div>
            <Header {...props} />
            <ContactContent />
            <Footer />
        </div>
    )
}

export default ContactPage