import axios from 'axios';
import React from 'react';
import cookie from 'react-cookies';
import { Link } from 'react-router-dom';
import Hypnosis from "react-cssfx-loading/lib/Hypnosis";

class ExamOverviewContent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            exams: [],
            dataLoaded: false
        }
    }

    async componentDidMount() {
        let jwt = cookie.load('jwt');
        const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/exams/get-all-exams-viewmodel`, {
            cache: 'no-store',
            headers: {
                'Authorization': `Bearer ${jwt}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
        this.setState({
            exams: data,
            dataLoaded: true
        })
    }
    render() {
        return (
            <div className="exam-overview">
                <div className="exam-overview-header-box">
                    <Link className="go-back" to="/admin"><ion-icon name="arrow-back-outline"></ion-icon> Back</Link>
                    <div className="exam-overview-header">
                        <h1>Beheer examens</h1>
                    </div>
                </div>
                <div className="exam-overview-body-box">
                    <div className="exam-overview-header-index">
                        <p>Naam</p>
                        <p>Aantal onderdelen</p>
                        <p>Aantal vragen</p>
                        <p>totale duur (minuten)</p>
                        <p></p>
                    </div>

                    <div className="exam-overview-body">
                        {this.state.dataLoaded ?
                            <div>
                                <p>Proefexamens:</p>
                                {this.state.exams.map((element) => (
                                    element.examName !== null && element.officialExam && 
                                    <div className="exam-overview-body-content" key={element.examID}>
                                        <div className="exam-overview-body-content-p1">
                                            <Link to={"/exam-exam-overview/" + element.examID}><p>{element.examName}</p></Link>
                                        </div>
                                        <div className="exam-overview-body-content-p2">
                                            <p>{element.amountOfSubjects} onderdelen</p>
                                        </div>
                                        <div className="exam-overview-body-content-p3">
                                            <p>{element.amountOfQuestions} vragen</p>
                                        </div>
                                        <div className="exam-overview-body-content-p4">
                                            <p>{element.totalTime} minuten</p>
                                        </div>
                                        <div className="exam-overview-body-content-l1">
                                            <Link to={"/edit-exam/" + element.examID}><ion-icon name="create-outline"></ion-icon></Link>
                                            <Link to={"/delete-exam/" + element.examID}><ion-icon name="trash-outline"></ion-icon></Link>
                                            <Link to={"/select-users-for-exam/" + element.examID}><ion-icon name="play-outline"></ion-icon></Link>
                                        </div>

                                    </div>

                                ))}
                            </div>
                            :
                            <div className="loader-animation">
                                <Hypnosis  color="#3399ff" width="50px" height="50px" duration="2s" />
                            </div>
                        }

                    </div>
                    <div className="exam-overview-footer">
                        <button><Link to="add-exam"><span>Voeg toe</span></Link></button>
                    </div>
                </div>
            </div>
        )
    }
}

export default ExamOverviewContent;