import React from 'react';
import Header from './Header';
import AddExamContent from './AddExamContent';
import Footer from './Footer';
import { useHistory } from 'react-router-dom';

function AddExamPage(props) {
    let history = useHistory();
    return (
        <div>
            <Header  {...props} />
            <AddExamContent history={history} />
            <Footer />
        </div>
    )
}

export default AddExamPage;