import axios from 'axios';
import React from 'react';
import cookie from 'react-cookies';
import Hypnosis from "react-cssfx-loading/lib/Hypnosis";

class SubjectInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            subjects: [],
            errorMessage: "",
            dataLoaded: false
        }
        this.checkElement.bind(this);
    }

    async componentDidMount() {
        let jwt = cookie.load('jwt');
        const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/subjects/subjects-data`, {
            headers: {
                'Authorization': `Bearer ${jwt}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });
        let tempList = data.map(subject => ({
            checked: false, subject
        }))
        this.setState({
            subjects: tempList,
            dataLoaded: true
        });
    }

    checkElement(index) {
        let subjectsCopy = this.state.subjects;
        subjectsCopy[index].checked = !subjectsCopy[index].checked;
        this.setState({
            subjects: subjectsCopy
        })
    }

    async makeExam() {
        let tempList = this.state.subjects.filter(subject => subject.checked);
        tempList = tempList.map(element => (
            element.subject
        ))
        if (tempList.length <= 0) {
            this.setState({
                errorMessage: "Gelieve eerst een onderdeel te selecteren voor je start!"
            })
        }
        else {
            let jwt = cookie.load('jwt');
            const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/exams/make-exam`, JSON.stringify(tempList), {
                headers: {
                    'Authorization': `Bearer ${jwt}`,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            });
            this.props.history.push(`/exam/${data}`);
        }
    }


    async makeCompleteExam() {
        let jwt = cookie.load('jwt');
        let tempList = this.state.subjects.map(element => (
            element.subject
        ))
        const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/exams/make-exam`, JSON.stringify(tempList), {
            headers: {
                'Authorization': `Bearer ${jwt}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });
        this.props.history.push(`/exam/${data}`);
    }

    async makePracticeExamToBeMade() {
        let jwt = cookie.load('jwt');
        const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/exams/get-exam-id`, {
            headers: {
                'Authorization': `Bearer ${jwt}`,
                'Token': jwt,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });
        this.props.history.push(`/exam/${data}`);
    }

    /* makeExamToBeMade() {
        //DEZE FUNCTIE WERD GEBRUIKT VOOR EEN EXHT EXAMEN MAAR WORDT NIET MEER GEBRUIKT DUS ALS WE DIE WEL TERUG WILLEN MOETEN WE NIET MEER NAAR GET-EXAM-ID, DIE IS NU ENKEL VOOR PRACTICEEXAMS
        let jwt = cookie.load('jwt');
        fetch(`${process.env.REACT_APP_API_URL}/exams/get-exam-id/`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${jwt}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(
            res => res.json()
        ).then(
            (result) => {
                this.setState({
                    exam: result
                })
                this.props.history.push(`/exam/${this.props.user.examToBeMade.examID}`);
            },
        )
    } */


    render() {
        return (
            <div className="subjects">
                <div className="subjects-header">
                    <h1>Onderdelen</h1>
                </div>
                {this.state.dataLoaded ?
                <div>
                    <div className="test">
                        <p>Om te oefenen klik op één of meerdere onderdelen, klik vervolgens op "start proefexamen" om te oefenen.</p>
                        <div className="errormsg">
                            {this.state.errorMessage !== "" &&
                                <p>{this.state.errorMessage}</p>
                            }
                        </div>
                        <div className="subjects-body">
                            {this.state.subjects !== undefined &&
                                this.state.subjects.map((element, index) => (
                                    <div key={element.subject.subjectID} onClick={() => this.checkElement(index)} className={element.checked === true ? 'subject-is-checked' : ''}>
                                        <h2>{element.subject.name}</h2>
                                    </div>
                                ))}
                        </div>
                        <button onClick={() => this.makeExam()}><span>Start oefenexamen</span></button>
                    </div>

                    <div className="subjects-footer">
                        <p>Ik wil een oefenexamen doen met alle vragen (132).</p>
                        <button onClick={() => this.makeCompleteExam()}><span>Start</span></button>
                    </div>
                    {this.props.user.practiceExamToBeMade !== null &&
                        <div className="subjects-footer-admin-box">
                            <p>Start het proef examen zweefvliegen.</p>
                            <button onClick={() => this.makePracticeExamToBeMade()}><span>Start proefexamen</span></button>
                        </div>
                    }
                </div>
                :
                <div className="loader-animation">
                    <Hypnosis  color="#3399ff" width="50px" height="50px" duration="2s" />
                </div>
                }
                {/* {this.props.user.examToBeMade !== null &&
                    <div className="subjects-footer-admin-box">
                        <p>Start het officieel theoretisch examen zweefvliegen.</p>
                        <button onClick={() => this.makeExamToBeMade()}><span>Start officieel theorie-examen</span></button>
                    </div>
                } */}
            </div>
        )
    }
}

export default SubjectInfo;