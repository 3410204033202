import React, {useState} from "react";
import {
    Link,
    useHistory
} from "react-router-dom";

import LoginForm from "./LoginForm";
import RegistrationForm from "./RegistrationForm";
import ForgotPasswordContent from "./ForgotPasswordContent";

function WelcomeHero() {

    let history = useHistory();
    const [loginform, setLoginform] = useState(true);
    const [forgotPasswordForm, setForgotPasswordForm] = useState("none");

    const login = () => {
        setLoginform(false);
    }

    const register = () => {
        setLoginform(true);
    }

    const showform = () => {
        setForgotPasswordForm("block");
    } 

    const hideform = () => {
        setForgotPasswordForm("none");
    } 

    return (
        <header className="welcome-hero">
            <div className="welcome-hero-content">
                <nav className="welcome-hero-content-header">
                    <img className="WelcomeImage" alt="logo" src={process.env.PUBLIC_URL + '/images/BlackLogo.png'} />
                </nav>
                <div className="welcome-hero-content-body">
                    {/* <div className="welcome-hero-content-body-title">
                        <h2>Oefensite voor de <span>theoriecursus zweefvliegen.</span></h2>
                        <p>Bereid je voor op het theoretisch examen voor het behalen van je zweefvliegvergunning.</p>
                    </div> */}
                    <div className="welcome-hero-content-body-form">
                        {loginform ? <LoginForm history={history}/> : <RegistrationForm history={history}/>}
                        <div className="loginform-links">
                            {loginform ? 
                            <>
                                <p>Ik heb nog geen account <span className="loginform-links-button" onClick={login}>Maak aan.</span></p>
                                <p>Ik ben mijn <span className="loginform-links-button" onClick={showform}>paswoord vergeten</span></p>
                            </> 
                                :
                                <p>Ik heb al een account <span className="loginform-links-button" onClick={register}>Meld aan.</span></p>
                            }
                        </div>
                    </div>
                </div>
                <div style={{display: forgotPasswordForm}} className="forgot-password-container">
                    <div className="forgot-password">
                        <span onClick={hideform} id='close'>&#10005;</span>
                        <ForgotPasswordContent />
                    </div>
                </div>
            </div>
        </header>
    )
}

export default WelcomeHero