import React from 'react';
import PdfButton from './PdfButton';
import Header from './Header';
import Footer from './Footer';
import { useHistory, useParams } from 'react-router-dom';

function PdfButtonPage(props) {
    let {examChanceID} = useParams();
    let history = useHistory();
    return (
        <div>
            <Header {...props}/>
            <PdfButton user={props.user} history={history} examChanceID={examChanceID}/>
            <Footer />
        </div>
    )
}

export default PdfButtonPage;