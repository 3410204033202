import React from 'react';
import { useHistory } from 'react-router-dom';
import App from './App';

function AppPage(props){
    let history = useHistory();
    return(
        <App history={history}/>
    )
}

export default AppPage;