import React from 'react';

class Footer extends React.Component {
    render() {
        return (
            <footer>
                <p>Liga van de Vlaamse zweefvliegclubs &copy;  RobbeRikLennert</p>
            </footer>
        );
    }
}

export default Footer;