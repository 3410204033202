import React from 'react';
import cookie from 'react-cookies';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import axios from 'axios';

function AddSubjectContent(props) {

    const validate = values => {
        const errors = {};
        if (!values.name) {
            errors.name = 'Gelieve een naam in te vullen voor het onderdeel.';
        } else if (values.name.length > 40) {
            errors.name = 'De naam van het onderdeel mag niet langer zijn dan 40 tekens.';
        }
        if (values.amountOfQuestionsPerExam < 1) {
            errors.amountOfQuestionsPerExam = 'Het aantal vragen per examen kan niet kleiner zijn dan 1.';
        }
        if (values.amountOfMinutesPerExam < 1) {
            errors.amountOfMinutesPerExam = 'Het aantal minuten per examen kan niet kleiner zijn dan 1.';
        }
        if (values.minimumScorePerExam < 1) {
            errors.minimumScorePerExam = 'De minimum score per examen kan niet kleiner zijn dan 1.';
        }
        if (values.minimumScorePerExam > values.amountOfQuestionsPerExam) {
            errors.amountOfQuestionsPerExam = 'Het aantal vragen per examen kan niet kleiner zijn dan De minimum score per examen.';
            errors.minimumScorePerExam = 'De minimum score per examen kan niet groter zijn dan Het aantal vragen per examen.';
        }
        return errors;
    };

    const formik = useFormik({
        initialValues: {
            name: '',
            amountOfQuestionsPerExam: 1,
            amountOfMinutesPerExam: 1,
            minimumScorePerExam: 1
        },
        validate,
        onSubmit: values => {
            addSubjectFunction(values)
        }
    })

    async function addSubjectFunction(values) {
        let jwt = cookie.load('jwt');
        await axios.post(`${process.env.REACT_APP_API_URL}/subjects/add-subject`, JSON.stringify(values, null, 2), { 
            headers: {
            'Authorization': `Bearer ${jwt}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
            }
        });
        props.history.push('/subject-overzicht');
    }

    return (
        <div className="add-subject">
            <div className="add-subject-header-box">
                <Link to="/subject-overzicht"><ion-icon name="arrow-back-outline"></ion-icon> Back</Link>
                <div className="add-subject-header">
                    <h1>Vul hier de gegevens in van het onderdeel dat je wil toevoegen.</h1>
                </div>
            </div>
            <div className="add-subject-body-box">
                <div className="add-subject-body">
                    <form onSubmit={formik.handleSubmit}>
                        <div className="add-subject-body-errormsg">
                            {formik.errors.name &&
                                <div>{formik.errors.name}</div>}
                            {formik.errors.amountOfQuestionsPerExam &&
                                <div>{formik.errors.amountOfQuestionsPerExam}</div>}
                            {formik.errors.amountOfMinutesPerExam &&
                                <div>{formik.errors.amountOfMinutesPerExam}</div>}
                            {formik.errors.minimumScorePerExam &&
                                <div>{formik.errors.minimumScorePerExam}</div>}
                        </div>
                        <div className="add-subject-body-element">
                            <label>Naam van het onderdeel:</label><input id="name" name="name" type="text" onChange={formik.handleChange} value={formik.values.name}></input>
                        </div>
                        <div className="add-subject-body-element">
                            <label>Aantal vragen per examen:</label><input id="amountOfQuestionsPerExam" name="amountOfQuestionsPerExam" type="number" onChange={formik.handleChange} value={formik.values.amountOfQuestionsPerExam}></input>
                        </div>
                        <div className="add-subject-body-element">
                            <label>Tijd per examen in minuten:</label><input id="amountOfMinutesPerExam" name="amountOfMinutesPerExam" type="number" onChange={formik.handleChange} value={formik.values.amountOfMinutesPerExam}></input>
                        </div>
                        <div className="add-subject-body-element">
                            <label>Minimum score om te slagen:</label><input id="minimumScorePerExam" name="minimumScorePerExam" type="number" onChange={formik.handleChange} value={formik.values.minimumScorePerExam}></input>
                        </div>
                        <div className="add-subject-body-element">
                            <button type="submit"><span>Voeg toe </span></button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default AddSubjectContent;