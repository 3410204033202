import React from 'react';
import cookie from 'react-cookies';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

class SelectUsersForExamContent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            users: [],
            allClubs: [],
            dataLoaded: false
        }
        this.handleUsersInputChange = this.handleUsersInputChange.bind(this);
    }

    componentDidMount() {
        let jwt = cookie.load('jwt');
        fetch(`${process.env.REACT_APP_API_URL}/users/get-all-users`, {
            method: 'GET',
            cache: 'no-store',
            headers: {
                'Authorization': `Bearer ${jwt}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(
            res => res.json()
        ).then(
            (result) => {
                let tempList = result.map(user => ({
                    checked: false, user
                }))
                let allClubs = [];
                tempList.forEach(element => {
                   if (!allClubs.includes(element.user.club)) allClubs.push(element.user.club);
                })
                allClubs = allClubs.map(club => ({
                    club,
                    open: false,
                }))
                this.setState({
                    users: tempList,
                    allClubs: allClubs,
                    dataLoaded: true
                })
            }
        )
    }

    handleUsersInputChange(index) {
        let usersCopy = this.state.users;
        usersCopy[index].checked = !usersCopy[index].checked;
        this.setState({
            users: usersCopy
        })
    }

    selectUsersFunction() {
        let tempList = this.state.users.filter(user => user.checked);
        tempList = tempList.map(element => (
            element.user
        ))
        let jwt = cookie.load('jwt');
        fetch(`${process.env.REACT_APP_API_URL}/users/update-exam-to-be-made`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${jwt}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                users: tempList, examID: this.props.examID
            })
        }).then(
            res => res.json()
        ).then(
            this.props.history.push('/admin')
        )
    }
    
    openClubTab(index) {
        let allClubsCopy = this.state.allClubs;
        allClubsCopy[index].open = !allClubsCopy[index].open;
        this.setState({
            allClubs: allClubsCopy
        })
    }

    render() {
        return (
            <div className="select-users-for-exam">
                <div className="select-users-for-exam-header-box">
                    <Link className="go-back" to="/exam-overview"><ion-icon name="arrow-back-outline"></ion-icon> Back</Link>
                    <div className="select-users-for-exam-header">
                        <h1>Selecteer hier de gebruikers die een examen willen afleggen.</h1>
                    </div>
                </div>
                <div className="select-users-for-exam-body-box">
                    {this.state.dataLoaded ?
                        <div className="select-users-for-exam-body">
                            {
                                this.state.allClubs.map((club, index) => (
                                    <div key={index}>
                                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center"}}>
                                            <div key="index" style={{ marginRight: "10px" }}> {club.club} </div>
                                            <ion-icon name="chevron-down-outline" onClick={() => this.openClubTab(index)}></ion-icon>
                                        </div>
                                        {club.open &&
                                            <div>
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <th></th>
                                                            <th>email</th>
                                                            <th>voornaam</th>
                                                            <th>naam</th>
                                                            <th>gebruikersnaam</th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.users.map((user, index) => (
                                                            user.user.authorities[0].authority !== "admin" && user.user.club === club.club && user.user.active &&   
                                                                <tr key={index}> 
                                                                    <td><input type="checkbox" className={user.user.username} value={user.userID} onClick={() => this.handleUsersInputChange(index)} /></td>
                                                                    <td>
                                                                        <p data-tip data-for={user.user.email}>{user.user.email}</p>
                                                                        <ReactTooltip id={user.user.email} type='dark' effect='solid'>
                                                                            <span>{user.user.email}</span>
                                                                        </ReactTooltip>
                                                                    </td>
                                                                    <td><p>{user.user.firstName}</p></td>
                                                                    <td><p>{user.user.lastName}</p></td>
                                                                    <td><p>{user.user.username}</p></td>
                                                                    
                                                                    <td>{user.user.practiceExamSucces ?
                                                                        <ion-icon name="checkmark-circle-outline"></ion-icon>
                                                                        :
                                                                        <p>X</p>
                                                                    }</td> 
                                                                </tr>
                                                        ))
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        }
                                    </div>
                                ))
                            }
                            <button onClick={() => this.selectUsersFunction()}><span>Selecteer</span></button>
                        </div>
                        :
                        <p>Aan het laden</p>
                    }
                </div>
            </div>
        )
    }
}

export default SelectUsersForExamContent;