import React from 'react';
import ReactDOM from 'react-dom';
import AppPage from './AppPage';
import * as serviceWorker from './serviceWorker';
import './index.scss';
import {BrowserRouter as Router} from 'react-router-dom';

ReactDOM.render(
  <React.StrictMode>
    <Router >
      <AppPage />
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

serviceWorker.unregister();