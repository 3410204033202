import React, { useState } from "react"
import { useFormik } from 'formik';
import axios from "axios";

function RegistrationForm(props) {
    const [feedbackError, setFeedbackError] = useState("");

    const validate = values => {
        const errors = {};
        if (!values.firstName) {
            errors.firstName = 'Gelieve je voornaam in te vullen.';
        }
        else if (values.firstName.length > 40) {
            errors.firstName = 'Voornaam kan niet langer zijn dan 40 tekens.';
        }
        if (!values.lastName) {
            errors.lastName = 'Gelieve je achternaam in te vullen.';
        }
        else if (values.lastName.length > 40) {
            errors.lastName = 'Achternaam kan niet langer zijn dan 40 tekens.';
        }
        if (!values.userName) {
            errors.userName = 'Gelieve je username in te vullen.';
        }
        else if (values.userName.length > 40) {
            errors.userName = 'Username kan niet langer zijn dan 40 tekens.';
        }
        if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(values.email))) {
            errors.email = 'Gelieve een geldig email adres in te vullen';
        }
        if (!values.password) {
            errors.password = 'Gelieve je password in te vullen.';
        }
        else if (values.password.length < 8) {
            errors.password = 'Password moet langer zijn dan 8 tekens.';
        }
        if (values.password !== values.confirmPassword) {
            errors.confirmPassword = 'Paswoorden komen niet overeen.';
        }
        if (values.club === null) {
            errors.club = 'Gelieve een club aan te duiden.';
        }
        return errors;
    };

    const formik = useFormik({
        initialValues: {
            firstName: '',
            lastName: '',
            userName: '',
            email: '',
            password: '',
            confirmPassword: '',
            club:'ACK'
        },
        validate,
        onSubmit: values => {
            registrationFunction(values)
        }
    })

    async function registrationFunction(values) {
        const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/users/user-registration`, JSON.stringify(values), { headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }});
        if(data !== ""){
            setFeedbackError(data);
        } else {
            window.location.reload(false)
        }
    }
    return (

        <div className="registrationform">

            <h1>Registratie</h1>

            <div className="registrationform-content">
                <form onSubmit={formik.handleSubmit}>
                    <div className="registrationform-content-errors">
                        {formik.errors.firstName &&
                            <div>{formik.errors.firstName}</div>}
                        {formik.errors.lastName &&
                            <div>{formik.errors.lastName}</div>}
                        {formik.errors.email &&
                            <div>{formik.errors.email}</div>}
                        {formik.errors.userName &&
                            <div>{formik.errors.userName}</div>}
                        {formik.errors.password &&
                            <div>{formik.errors.password}</div>}
                        {formik.errors.confirmPassword &&
                            <div>{formik.errors.confirmPassword}</div>}
                        {formik.errors.club &&
                            <div>{formik.errors.club}</div>}
                        {feedbackError &&
                            <div>{feedbackError}</div>}
                    </div>

                    <div className="registrationform-content-body">
                        <div className="registrationform-content-body-name">
                            <div>
                                <input id="firstName" name="firstName" type="text" onChange={formik.handleChange} value={formik.values.firstName} />
                                <label>Voornaam</label>
                            </div>
                            <div>
                                <input id="lastName" name="lastName" type="text" onChange={formik.handleChange} value={formik.values.lastName} />
                                <label>Achternaam</label>
                            </div>
                        </div>
                        <div className="registrationform-content-body-email">
                            <div>
                                <input id="email" name="email" type="text" onChange={formik.handleChange} value={formik.values.email} />
                                <label>Email</label>
                            </div>
                        </div>
                        <div className="registrationform-content-body-usernameclub">
                            <div>
                                <input id="userName" name="userName" type="text" onChange={formik.handleChange} value={formik.values.userName} />
                                <label>Gebruikersnaam</label>
                            </div>
                            <div> 
                                <select id="club" name="club" onChange={formik.handleChange} value={formik.values.club}>
                                    <option value="ACK">ACK</option>
                                    <option value="ALB">ALB</option>
                                    <option value="BZC">BZC</option>
                                    <option value="DAC">DAC</option>
                                    <option value="KAC">KAC</option>
                                    <option value="KAZM">KAZM</option>
                                    <option value="KFC">KFC</option>
                                    <option value="KVDW">KVDW</option>
                                    <option value="LUAC">LUAC</option>
                                    <option value="LZC">LZC</option>
                                    <option value="VZA">VZA</option>
                                    <option value="VZP">VZP</option>
                                    <option value="LV">LV</option>
                                </select>
                                <label>Club</label>
                            </div>
                        </div>
                        
                        <div className="registrationform-content-body-password">
                            <div>
                                <input id="password" name="password" type="password" onChange={formik.handleChange} value={formik.values.password} />
                                <label>Paswoord</label>
                            </div>
                            <div>
                                <input id="confirmPassword" name="confirmPassword" type="password" onChange={formik.handleChange} value={formik.values.confirmPassword} />
                                <label>Bevestig paswoord</label>
                            </div>
                        </div>
                    </div>

                    <button text="log in" type="submit">Registreren</button>
                </form>
            </div>
        </div>
    )
}

export default RegistrationForm