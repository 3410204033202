import axios from 'axios';
import React from 'react';
import cookie from 'react-cookies';
import { Link } from 'react-router-dom';
import Hypnosis from "react-cssfx-loading/lib/Hypnosis";

class QuestionOverviewContent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            subjects: [],
            dataLoaded: false
        }
    }

    async componentDidMount() {
        let jwt = cookie.load('jwt');
        const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/subjects/get-questions-viewmodel`, {
            method: 'GET',
            cache: 'no-store',
            headers: {
                'Authorization': `Bearer ${jwt}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });
        this.setState({
            subjects: data,
            dataLoaded: true
        })
    }
    render() {
        return (
            <div className="question-overview">
                <div className="question-overview-box">
                    <Link className="go-back" to="/admin"><ion-icon name="arrow-back-outline"></ion-icon> Back</Link>
                    <div className="question-overview-header">
                        <h1>Beheer vragen</h1>
                    </div>
                </div>
                
                    <div className="question-overview-body-box">
                    {this.state.dataLoaded ?
                        <div>
                            <div className="question-overview-body">
                                {this.state.subjects.map((element) => (
                                    <div className="question-overview-body-content" key={element.subjectID}>
                                        <div className="question-overview-body-content-p1">
                                            <Link to={"question-question-overview/" + element.subjectID}>
                                                <div className="question-overview-body-content-p1-naam">
                                                    <p>{element.subjectName}</p>
                                                </div>
                                                <div className="question-overview-body-content-p1-nummer">
                                                    <p className="number">{element.amountOfQuestionsPerSubject}</p>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                ))
                                }
                            </div>
                            <div className="question-overview-footer">
                                <button><Link to="/add-question"><span>Voeg vraag toe</span></Link></button>
                            </div>
                        </div>
                        :
                        <div className="loader-animation">
                            <Hypnosis  color="#3399ff" width="50px" height="50px" duration="2s" />
                        </div>
                        }
                    </div>
                    
                
            </div>
        )
    }
}

export default QuestionOverviewContent;