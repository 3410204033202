import React from 'react';
import cookie from 'react-cookies';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';

function AddExamContent(props) {

    const validate = values => {
        const errors = {};
        if (!values.examName) {
            errors.examName = 'Gelieve een naam in te vullen voor het examen.';
        } else if (values.examName.length > 40) {
            errors.examName = 'De naam van het examen mag niet langer zijn dan 40 tekens.';
        }
        return errors;
    };

    const formik = useFormik({
        initialValues: {
            examName: '',
            officialExam: false
        },
        validate,
        onSubmit: values => {
            addExamFunction(values)
        }
    })

    function addExamFunction(values) {
        let jwt = cookie.load('jwt');
        fetch(`${process.env.REACT_APP_API_URL}/exams/add-exam`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${jwt}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(values)
        }).then(
            res => res.json()
        ).then(
            () => {
                props.history.push('/exam-overview')
            }
        )
    }

    return (
        <div className="add-exam">
            <div className="add-exam-header-box">
                <Link to="/exam-overview"><ion-icon name="arrow-back-outline"></ion-icon> Back</Link>
                <div className="add-exam-header">
                    <h1>Vul hier de gegevens in van het examen dat je wil toevoegen.</h1>
                </div>
            </div>
            <div className="add-exam-body-box">
                <div className="add-exam-body">
                    <form onSubmit={formik.handleSubmit}>
                        {formik.errors.examName &&
                            <div className="add-exam-body-errormsg">
                                {formik.errors.examName}
                            </div>}
                        <div className="add-exam-body-element">
                            <label>Naam van het examen:</label>
                            <input id="examName" name="examName" type="text" onChange={formik.handleChange} value={formik.values.examName}></input>
                            <label><input type="checkbox" id="officialExam" name="officialExam" onChange={formik.handleChange} value={formik.values.officialExam}/>Vink aan als dit een officieel examen is.</label>
                        </div>
                        <div className="add-exam-body-element">
                            <button type="submit"><span>Voeg toe</span></button>

                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default AddExamContent;