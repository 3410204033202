import React, { useState, useEffect } from 'react';
import cookie from 'react-cookies';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import axios from 'axios';
import Hypnosis from "react-cssfx-loading/lib/Hypnosis";

function EditSubjectContent(props) {
    const [subject, setSubject] = useState({});
    const [dataLoaded, setDataLoaded] = useState(false);

    useEffect(() => {
        async function fetchData(){
            let unMounted = false;
            let jwt = cookie.load('jwt');
            const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/subjects/${props.subjectID}`, {
                headers: {
                    'Authorization': `Bearer ${jwt}`,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            });
            setSubject(data);
            if (!unMounted) {
                setDataLoaded(true)
            }
            return () => { unMounted = true }
        }
        fetchData();
    }, []);

    const validate = values => {
        const errors = {};
        if (!values.name) {
            errors.name = 'Gelieve een naam in te vullen voor het onderdeel.';
        } else if (values.name.length > 40) {
            errors.name = 'De naam van het onderdeel mag niet langer zijn dan 40 tekens.';
        }
        if (values.amountOfQuestionsPerExam < 1) {
            errors.amountOfQuestionsPerExam = 'Het aantal vragen per examen kan niet kleiner zijn dan 1.';
        }
        if (values.amountOfMinutesPerExam < 1) {
            errors.amountOfMinutesPerExam = 'Het aantal minuten per examen kan niet kleiner zijn dan 1.';
        }
        if (values.minimumScorePerExam < 1) {
            errors.minimumScorePerExam = 'De minimum score per examen kan niet kleiner zijn dan 1.';
        }
        if (values.minimumScorePerExam > values.amountOfQuestionsPerExam) {
            errors.amountOfQuestionsPerExam = 'Het aantal vragen per examen kan niet kleiner zijn dan de minimum score per examen.';
            errors.minimumScorePerExam = 'De minimum score per examen kan niet groter zijn dan het aantal vragen per examen.';
        }
        return errors;
    };

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: subject.name,
            amountOfQuestionsPerExam: subject.amountOfQuestionsPerExam,
            amountOfMinutesPerExam: subject.amountOfMinutesPerExam,
            minimumScorePerExam: subject.minimumScorePerExam
        },
        validate,
        onSubmit: values => {
            editSubjectFunction(values)
        }
    })

    async function editSubjectFunction(values) {
        let jwt = cookie.load('jwt');
        await axios.post(`${process.env.REACT_APP_API_URL}/subjects/edit-subject`, JSON.stringify({ subjectID: props.subjectID, name: values.name, amountOfQuestionsPerExam: values.amountOfQuestionsPerExam, amountOfMinutesPerExam: values.amountOfMinutesPerExam, minimumScorePerExam: values.minimumScorePerExam }), { 
            headers: {
                'Authorization': `Bearer ${jwt}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });
        props.history.push('/subject-overzicht')
    }

    return (
        <div className="edit-subject">
            <div className="edit-subject-header-box">
                <Link to="/subject-overzicht"><ion-icon name="arrow-back-outline"></ion-icon> Back</Link>
                <div className="edit-subject-header">
                    <h1>Pas hier de velden die je wil veranderen aan.</h1>
                </div>
            </div>
            <div className="edit-subject-body-box">
                {dataLoaded ?
                    <div className="edit-subject-body">
                        <form onSubmit={formik.handleSubmit}>
                            <div className="edit-subject-body-errormsg">
                                {formik.errors.name &&
                                    <div>{formik.errors.name}</div>}
                                {formik.errors.amountOfQuestionsPerExam &&
                                    <div>{formik.errors.amountOfQuestionsPerExam}</div>}
                                {formik.errors.amountOfMinutesPerExam &&
                                    <div>{formik.errors.amountOfMinutesPerExam}</div>}
                                {formik.errors.minimumScorePerExam &&
                                    <div>{formik.errors.minimumScorePerExam}</div>}
                            </div>
                            <div className="edit-subject-body-element">
                                <label>Naam van het onderdeel:</label><input id="name" name="name" type="text" onChange={formik.handleChange} value={formik.values.name}></input>
                            </div>
                            <div className="edit-subject-body-element">
                                <label>Aantal vragen per examen:</label><input id="amountOfQuestionsPerExam" name="amountOfQuestionsPerExam" type="number" onChange={formik.handleChange} value={formik.values.amountOfQuestionsPerExam}></input>
                            </div>
                            <div className="edit-subject-body-element">
                                <label>Tijd per examen in minuten:</label><input id="amountOfMinutesPerExam" name="amountOfMinutesPerExam" type="number" onChange={formik.handleChange} value={formik.values.amountOfMinutesPerExam}></input>
                            </div>
                            <div className="edit-subject-body-element">
                                <label>Minimum score om te slagen:</label><input id="minimumScorePerExam" name="minimumScorePerExam" type="number" onChange={formik.handleChange} value={formik.values.minimumScorePerExam}></input>
                            </div>
                            <div className="edit-subject-body-element">
                                <button type="submit"><span>Pas aan</span></button>
                            </div>
                        </form>
                    </div>
                    :
                    <div className="loader-animation">
                        <Hypnosis  color="#3399ff" width="50px" height="50px" duration="2s" />
                    </div>
                }
            </div>
        </div>
    )
}

export default EditSubjectContent;