import React from 'react';
import PasswordResetContent from './PasswordResetContent';
import { useHistory, useParams } from 'react-router-dom';

function PasswordResetPage(props) {
    let { keyID } = useParams();
    let history = useHistory();
    return (
        <div className="forgotPasswordPage">
            <PasswordResetContent history={history} keyID={keyID} />
        </div>
    )
}

export default PasswordResetPage