import React, { useState, useEffect } from 'react';
import cookie from 'react-cookies';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import axios from 'axios';
import Hypnosis from "react-cssfx-loading/lib/Hypnosis";

function EditQuestionContent(props) {
    const [question, setQuestion] = useState({});
    const [image, setImage] = useState({});
    const [exams, setExams] = useState([]);
    const [questionLoaded, setQuestionLoaded] = useState(false);
    const [examsLoaded, setExamsLoaded] = useState(false);

    useEffect(() => {
        async function fetchData() {
            let unMounted = false;
            let jwt = cookie.load('jwt');
            const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/questions/get-question-view-model/${props.questionID}`, {
                headers: {
                    'Authorization': `Bearer ${jwt}`,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            });
            setQuestion(data.question)
            setExams(data.exams)
            if (data.question.image != null) {
                setImage(data.question.image)
            }
            if (!unMounted) {
                setQuestionLoaded(true)
                setExamsLoaded(true)
            }
            return () => { unMounted = true }
        }
        fetchData();
    }, []);

    const validate = values => {
        const errors = {};
        if (!values.question) {
            errors.question = 'Gelieve een opgave in te vullen voor de vraag.';
        }
        else if (values.question.length > 4000) {
            errors.question = 'De opgave van de vraag mag niet langer zijn dan 4000 tekens.';
        }
        if (!values.correctAnswer) {
            errors.correctAnswer = 'Gelieve een correct antwoord in te vullen voor de vraag.';
        }
        else if (values.correctAnswer.length > 4000) {
            errors.correctAnswer = 'Het correcte antwoord van de vraag mag niet langer zijn dan 4000 tekens.';
        }
        if (!values.possibleAnswer1) {
            errors.possibleAnswer1 = 'Gelieve mogelijk antwoord 1 in te vullen voor de vraag.';
        }
        else if (values.possibleAnswer1.length > 4000) {
            errors.possibleAnswer1 = 'mogelijk antwoord 1 van de vraag mag niet langer zijn dan 4000 tekens.';
        }
        if (!values.possibleAnswer2) {
            errors.possibleAnswer2 = 'Gelieve mogelijk antwoord 2 in te vullen voor de vraag.';
        }
        else if (values.possibleAnswer2.length > 4000) {
            errors.possibleAnswer2 = 'mogelijk antwoord 2 van de vraag mag niet langer zijn dan 4000 tekens.';
        }
        if (!values.possibleAnswer3) {
            errors.possibleAnswer3 = 'Gelieve mogelijk antwoord 3 in te vullen voor de vraag.';
        }
        else if (values.possibleAnswer3.length > 4000) {
            errors.possibleAnswer3 = 'mogelijk antwoord 3 van de vraag mag niet langer zijn dan 4000 tekens.';
        }
        if (!values.possibleAnswer4) {
            errors.possibleAnswer4 = 'Gelieve mogelijk antwoord 4 in te vullen voor de vraag.';
        }
        else if (values.possibleAnswer4.length > 4000) {
            errors.possibleAnswer4 = 'mogelijk antwoord 4 van de vraag mag niet langer zijn dan 4000 tekens.';
        }
        if (values.explanation.length > 4000) {
            errors.explanation = 'De uitleg van de vraag mag niet langer zijn dan 4000 tekens.';
        }

        return errors;
    };

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            question: question.question,
            correctAnswer: question.correctAnswer,
            possibleAnswer1: question.possibleAnswer1,
            possibleAnswer2: question.possibleAnswer2,
            possibleAnswer3: question.possibleAnswer3,
            possibleAnswer4: question.possibleAnswer4,
            subjectID: question.subjectID,
            difficulty: question.difficulty,
            explanation: question.explanation,
            examsIDs: question.examsIDs
        },
        validate,
        onSubmit: values => {
            editQuestionFunction(values)
        }
    })

    function handleImageInputChange(event) {
        event.preventDefault();
        setImage(event.target.files[0])
    };

    function editQuestionFunction(values) {
        let jwt = cookie.load('jwt');
        const formData = new FormData();
        formData.append('image', new Blob([image], {
            type: "multipart/form-data"
        }), image.name);
        formData.append('question', new Blob([JSON.stringify(
            values
        )], {
            type: "application/json"
        }))
        fetch(`${process.env.REACT_APP_API_URL}/questions/edit-question/${props.questionID}`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${jwt}`
            },
            body: formData
        }).then(
            res => res.json()
        ).then(
            () => {
                props.history.push('/question-overview')
            }
        )
    }

    return (
        <div className="edit-question">
            <div className="edit-question-header-box">
                <Link to="/question-overview"><ion-icon name="arrow-back-outline"></ion-icon> Back</Link>
                <div className="edit-question-header">
                    <h1>Pas hier de velden die je wil veranderen aan.</h1>
                </div>
            </div>
            <div className="edit-question-body-box">
                {questionLoaded && examsLoaded ?
                    <div className="edit-question-body">
                        <form onSubmit={formik.handleSubmit}>
                            <div className="edit-question-body-errormsg">
                                {formik.errors.question &&
                                    <div>{formik.errors.question}</div>}
                                {formik.errors.correctAnswer &&
                                    <div>{formik.errors.correctAnswer}</div>}
                                {formik.errors.possibleAnswer1 &&
                                    <div>{formik.errors.possibleAnswer1}</div>}
                                {formik.errors.possibleAnswer2 &&
                                    <div>{formik.errors.possibleAnswer2}</div>}
                                {formik.errors.possibleAnswer3 &&
                                    <div>{formik.errors.possibleAnswer3}</div>}
                                {formik.errors.possibleAnswer4 &&
                                    <div>{formik.errors.possibleAnswer4}</div>}
                                {formik.errors.explanation &&
                                    <div>{formik.errors.explanation}</div>}
                            </div>
                            <div className="edit-question-body-element">
                                <div className="edit-question-body-element-content">
                                    <label>Opgave:</label><input id="question" name="question" type="text" onChange={formik.handleChange} value={formik.values.question}></input>
                                </div>

                                <div className="edit-question-body-element-content">
                                    <label>Mogelijk antwoord 1:</label><input id="possibleAnswer1" name="possibleAnswer1" type="text" onChange={formik.handleChange} value={formik.values.possibleAnswer1}></input>
                                </div>
                                <div className="edit-question-body-element-content">
                                    <label>Mogelijk antwoord 2:</label><input id="possibleAnswer2" name="possibleAnswer2" type="text" onChange={formik.handleChange} value={formik.values.possibleAnswer2}></input>
                                </div>
                                <div className="edit-question-body-element-content">
                                    <label>Mogelijk antwoord 3:</label><input id="possibleAnswer3" name="possibleAnswer3" type="text" onChange={formik.handleChange} value={formik.values.possibleAnswer3}></input>
                                </div>
                                <div className="edit-question-body-element-content">
                                    <label>Mogelijk antwoord 4:</label><input id="possibleAnswer4" name="possibleAnswer4" type="text" onChange={formik.handleChange} value={formik.values.possibleAnswer4}></input>
                                </div>
                                <div className="edit-question-body-element-content-dropdown">
                                    <p>Huidig correct Antwoord: </p>
                                    <select id="correctAnswer" name="correctAnswer" onChange={formik.handleChange}>
                                        <option value={formik.values.possibleAnswer1}>Mogelijk antwoord 1</option>
                                        <option value={formik.values.possibleAnswer2}>Mogelijk antwoord 2</option>
                                        <option value={formik.values.possibleAnswer3}>Mogelijk antwoord 3</option>
                                        <option value={formik.values.possibleAnswer4}>Mogelijk antwoord 4</option>
                                    </select>
                                </div>
                                <div className="current-correct-answer">
                                    <p>{formik.values.correctAnswer}</p>
                                </div>
                                <div className="edit-question-body-element2-explanation">
                                    <label>Uitleg: </label><textarea id="explanation" name="explanation" type="text" onChange={formik.handleChange} value={formik.values.explanation}></textarea>
                                </div>

                                <div className="edit-question-body-element-content">
                                    <label>Selecteer een afbeelding:</label>
                                    <input id="image" name="image" type="file" onChange={(event) => handleImageInputChange(event)}></input>
                                </div>
                                <div className="edit-question-body-element-content-img">
                                    {image != null &&
                                        <img alt="" src={image.fileDownloadUrl} />
                                    }
                                </div>
                            </div>
                            <div className="edit-question-body-element2">
                                <div className="edit-question-body-element2-tags">
                                    <p className="examen">Examen:</p>
                                    <p className="moeilijkheidsgraad">Moeilijkheidsgraad:</p>
                                </div>
                                <div className="edit-question-body-element2-moeilijkheidsgraad">
                                    <p><input type="radio" name="difficulty" checked={formik.values.difficulty === "EASY"} value="EASY" onChange={formik.handleChange} />makkelijk</p>
                                    <p><input type="radio" name="difficulty" checked={formik.values.difficulty === "MEDIUM"} value="MEDIUM" onChange={formik.handleChange} />gemiddeld</p>
                                    <p><input type="radio" name="difficulty" checked={formik.values.difficulty === "HARD"} value="HARD" onChange={formik.handleChange} />moeilijk</p>
                                </div>
                                {exams.map((element) => (
                                    <div key={element.examID} className="edit-question-body-element2-checkbox">
                                        {element.examName !== null &&
                                            <div>
                                                <input type="checkbox" id="examsIDs" name="examsIDs" value={element.examID} checked={formik.values.examsIDs !== undefined ? formik.values.examsIDs.includes(element.examID) : false} onChange={formik.handleChange} />
                                                <label className={element.examName}>{element.examName}</label>
                                            </div>
                                        }
                                    </div>
                                ))}
                            </div>
                            <div className="edit-question-body-footer">
                                <button type="submit"><span>Pas aan</span></button>
                            </div>
                        </form>
                    </div>
                    :
                    <div className="loader-animation">
                        <Hypnosis  color="#3399ff" width="50px" height="50px" duration="2s" />
                    </div>
                }
            </div>
        </div>
    )
}

export default EditQuestionContent;