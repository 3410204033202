import React from 'react';
import AccountContent from './AccountContent';
import Header from './Header';
import Footer from './Footer';
import { useHistory } from 'react-router-dom';

function AccountPage(props) {
    let history = useHistory();
    return (
        <div>
            <Header {...props} />
            <AccountContent history={history} {...props} />
            <Footer />
        </div>
    )
}

export default AccountPage