import React from 'react';
import Header from './Header';
import DeleteQuestionContent from './DeleteQuestionContent';
import Footer from './Footer';
import { useHistory, useParams } from 'react-router-dom';

function DeleteQuestionPage(props) {
    let {questionID} = useParams();
    let history = useHistory();
    return (
        <div>
            <Header {...props} />
            <DeleteQuestionContent history={history} questionID={questionID}/>
            <Footer />
        </div>
    )
}

export default DeleteQuestionPage;