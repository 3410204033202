import React from 'react';
import cookie from 'react-cookies';
import {
    Link, NavLink
} from "react-router-dom";

class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            toggleRender: false
        }
        this.arrayRef = [];
        for (let i = 0; i < 5; i++) {
            this.arrayRef[i] = false;
        }
        this.addActiveToLink.bind(this);
        this.logoutFunction.bind(this);
    }

    logoutFunction() {
        cookie.remove('jwt');
    }

    addActiveToLink(index) {
        for (let i = 0; i < this.arrayRef.length; i++) {
            this.arrayRef[i] = false;
        }
        this.arrayRef[index] = true;
        this.setState({
            toggleRender: !this.state.toggleRender
        })
    }

    render() {
        return (
            <nav className="navbar">
                <div className="navbar-content">
                    <Link to="/subjects"><img src={process.env.PUBLIC_URL + '/images/logo.png'} alt="" /></Link>
                    <div className="navbar-content-navigation">
                        <ul className="navbar-content-navigation-links">
                            <li>
                                <NavLink activeClassName="active-link" to="/subjects">Maak examen</NavLink>
                            </li>
                            <li>
                                <NavLink activeClassName="active-link" to="/contact">Info</NavLink>
                            </li>
                            <li>
                                <NavLink activeClassName="active-link" to="/account">Account</NavLink>
                            </li>
                            {this.props.user.authorities !== undefined ?
                                this.props.user.authorities[0].authority === "admin" || this.props.user.authorities[0].authority === "moderator" ?
                                    <li>
                                        <NavLink activeClassName="active-link" to="/admin">Admin page</NavLink>
                                    </li>
                                    :
                                    <p></p>
                                :
                                <p></p>
                            }

                            <li>
                                <Link to="/welcome-page" onClick={() => this.logoutFunction()}>Log uit</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        );
    }
}

export default Header