import React from 'react';
import cookie from 'react-cookies';

class LoginForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userName: "",
            password: "",
            errorHasOccured: false,
            incorrectlyLoggedIn: false
        }
        this.handleUserNameInputChange = this.handleUserNameInputChange.bind(this);
        this.handlePasswordInputChange = this.handlePasswordInputChange.bind(this);
    }

    handleUserNameInputChange(event) {
        this.setState(
            {
                userName: event.target.value
            });
    }

    handlePasswordInputChange(event) {
        this.setState(
            {
                password: event.target.value
            });

    }

    loginFunction() {
        fetch(`${process.env.REACT_APP_API_URL}/authenticate/login`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                username: this.state.userName, password: this.state.password
            })

        }).then(
            (res) => {
                if (!res.ok) {
                    throw Error(res);
                }
                return res.json();
            }
        ).then(
            (res) => {
                if (res.isLoggedIn) {
                    cookie.save('jwt', res.jwt, { path: '/' })
                    this.props.history.push('/subjects')
                }
                else {
                    this.setState({
                        incorrectlyLoggedIn: true
                    })
                }
            }
        ).catch(
            (res) => {
                if (res.status === 401) {
                    this.setState({
                        errorHasOccured: true
                    })
                }
                else {
                    this.setState({
                        incorrectlyLoggedIn: true
                    })
                }
            }
        );
    }

    render() {
        return (
            <div className="loginform">

                <h1>Log in</h1>

                <div className="loginform-content">
                    <div className="loginform-content-error">
                        {this.state.errorHasOccured &&
                            <p>Er is een fout opgetreden bij het afhandelen van de login.</p>
                        }
                        {this.state.incorrectlyLoggedIn &&
                            <p>Gebruikersnaam of wachtwoord is niet correct.</p>
                        }
                    </div>
                    <div className='loginform-content-fields'>
                        <div>
                            <input className="" type="text" onChange={this.handleUserNameInputChange} />
                            <label>gebruikersnaam</label>
                        </div>
                        <div>
                            <input className="" type="password" onChange={this.handlePasswordInputChange} />
                            <label>Paswoord</label>
                        </div>
                    </div>
                    <button text="log in" onClick={() => this.loginFunction()}>Aanmelden</button>
                </div>
            </div>
        )
    }
}

export default LoginForm;